<template>
  <div class="home" >
    <total-user
      :today-user="todayUser"
      :growth-last-day="growthLastDay"
      :growth-last-month="growthLastMonth"
    />
  </div>
</template>

<script>
  import TotalUser from '@/components/TotalUser/index'
  import { ref, onMounted, onUnmounted } from 'vue'
  export default {
    name: 'TestTotalUser',
    components: { TotalUser },
    setup () {
      const todayUser = ref(20)
      const growthLastDay = ref(50)
      const growthLastMonth = ref(30)
      let timer

      const update = () => {
        todayUser.value += 1
        growthLastDay.value += 2
        growthLastMonth.value += 5
      }

      onMounted(() => {
        timer = setInterval(() => {
          update()
        }, 30000)
      })

      onUnmounted(() => {
        timer && clearInterval(timer)
      })

      return {
        todayUser,
        growthLastDay,
        growthLastMonth
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
