<template>
  <div class="home" >
    <total-host-status :data="hostStatusData" />
  </div>
</template>

<script>
  import TotalHostStatus from '@/components/TotalHostStatus/index'
  import useScreenData from '../hooks/useScreenData'
  export default {
    name: 'TestTotalHostStatus',
    components: { TotalHostStatus },
    setup () {
      return {
        ...useScreenData()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
