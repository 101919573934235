<template>
  <div class="home" >
    <warning-radar :data="radarData" />
  </div>
</template>

<script>
  import WarningRadar from '../components/WarningRadar/index'
  import useScreenData from '../hooks/useScreenData'

  export default {
    name: 'TestWarningRadar',
    components: { WarningRadar },
    setup () {
      return {
        ...useScreenData()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
