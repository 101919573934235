<template>
  <div class="home" >
    <total-resource-top-n
      legendData="南天云资源使用排名"
      legendDataEn="Top10"
      :data="resourceTop10"
    />
  </div>
</template>

<script>
  import TotalResourceTopN from '@/components/TotalResourceTopN/index'
  import useScreenData from '../hooks/useScreenData'

  export default {
    name: 'TestTotalDial',
    components: { TotalResourceTopN },
    setup () {
      return {
        ...useScreenData()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
