<template>
  <div class="order-map">
    <div class="loading" v-if="loading">加载中...</div>
    <nt-echarts :options="options" v-else/>
  </div>
</template>

<script>
  import { ref, onMounted, watch } from 'vue'
  import echarts from 'echarts'
  import NtEcharts from '../NtEcharts/NtEcharts'

  export default {
    name: 'OrderMap2',
    props: {
      category: Array,
      categoryData: Array,
      mapData: Object,
      lineData: Array,
      effectData: Array,
      barData: Array,
      loadFlag: Boolean
    },
    components: { NtEcharts },
    setup(props) {
      const loading = ref(true)
      const options = ref({})
      const update = () => {
        if (props.loadFlag) {
          return
        }
        const colors = [
          ['#1DE9B6', '#1DE9B6', '#FFDB5C', '#FFDB5C', '#04B9FF', '#04B9FF'],
          ['#1DE9B6', '#F46E36', '#04B9FF', '#5DBD32', '#FFC809', '#FB95D5', '#BDA29A', '#6E7074', '#546570', '#C4CCD3'],
          ['#37A2DA', '#67E0E3', '#32C5E9', '#9FE6B8', '#FFDB5C', '#FF9F7F', '#FB7293', '#E062AE', '#E690D1', '#E7BCF3', '#9D96F5', '#8378EA', '#8378EA'],
          ['#DD6B66', '#759AA0', '#E69D87', '#8DC1A9', '#EA7E53', '#EEDD78', '#73A373', '#73B9BC', '#7289AB', '#91CA8C', '#F49F42']
        ]
        const colorIndex = 1
        echarts.registerMap('china', props.mapData)
        const _option = {
          timeline: {
            data: props.category,
            axisType: 'category',
            autoPlay: true,
            playInterval: 30000,
            left: '10%',
            right: '5%',
            bottom: '3%',
            width: '80%',
            label: {
              normal: {
                textStyle: {
                  color: '#ddd'
                }
              },
              emphasis: {
                textStyle: {
                  color: '#fff'
                }
              }
            },
            symbolSize: 10,
            lineStyle: {
              color: '#555'
            },
            checkpointStyle: {
              borderColor: '#777',
              borderWidth: 2,
              color: '#6462cc'
            },
            controlStyle: {
              showNextBtn: true,
              showPrevBtn: true,
              normal: {
                color: '#666',
                borderColor: '#666'
              },
              emphasis: {
                color: '#aaa',
                borderColor: '#aaa'
              }
            }
          },
          baseOption: {
            backgroundColor: '#424446',
            grid: {
              right: '5%',
              top: '10%',
              bottom: '15%',
              width: '20%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
                shadowStyle: {
                  color: 'rgba(150,150,150,0.1)'
                }
              }
            },
            geo: {
              map: 'china',
              zoom: 1.1,
              roam: false,
              scaleLimit: {
                min: 0.5,
                max: 3
              },
              center: [113.83531246, 34.0267395887],
              itemStyle: {
                normal: {
                  borderColor: 'rgba(147,235,248,1)',
                  borderWidth: 1,
                  areaColor: {
                    type: 'radial',
                    x: 0.5,
                    y: 0.5,
                    r: 0.8,
                    colorStops: [{
                      offset: 0, color: 'rgba(147,235,248,0)'
                    }, {
                      offset: 1, color: 'rgba(147,235,248,0.2)'
                    }],
                    global: false // 缺省为 false
                  },
                  shadowColor: 'rgba(128,217,248,1)',
                  shadowOffsetX: -2,
                  shadowOffsetY: 2,
                  shadowBlur: 10
                },
                emphasis: {
                  areaColor: '#389BB7',
                  borderWidth: 0
                }
              },
              label: {
                emphasis: {
                  show: false
                }
              }
            }
          },
          options: []
        }
        for (let i = 0; i < props.category.length; i++) {
          _option.options.push({
            title: [{
              text: '外卖销售大盘',
              subtext: '数据由外卖大数据提供',
              left: '2%',
              top: '2%',
              textStyle: {
                color: '#fff',
                fontSize: 35,
                fontWeight: 700
              }
            }, {
              id: 'statistic',
              text: `${props.category[i]}销售额统计情况`,
              left: '75%',
              top: '3%',
              textStyle: {
                color: '#fff',
                fontSize: 25
              }
            }],
            xAxis: {
              type: 'value',
              position: 'top',
              min: 0,
              boundaryGap: false,
              splitLine: {
                show: false
              },
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                margin: 2,
                textStyle: {
                  color: '#aaa'
                }
              }
            },
            yAxis: {
              type: 'category',
              data: props.categoryData[i],
              axisLine: {
                lineStyle: {
                  color: '#ddd'
                }
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                interval: 0,
                textStyle: {
                  color: '#ddd'
                }
              }
            },
            series: [{
              type: 'effectScatter',
              coordinateSystem: 'geo',
              data: props.effectData[i],
              // 图标大小
              symbolSize: function (val) {
                return val[2] / 10
              },
              // 涟漪相关配置
              rippleEffect: {
                brushType: 'stroke'
              },
              hoverAnimation: true,
              label: {
                normal: {
                  show: true,
                  position: 'right',
                  formatter: function (params) {
                    return params.data.name
                  }
                }
              },
              itemStyle: {
                normal: {
                  color: colors[colorIndex][i],
                  shadowColor: colors[colorIndex][i],
                  shadowBlur: 10
                }
              },
              zlevel: 1
            },
              {
              type: 'lines',
              data: props.lineData[i],
              effect: {
                show: true,
                period: 4,
                symbol: 'arrow',
                symbolSize: 3,
                trailLength: 0.02
              },
              lineStyle: {
                normal: {
                  color: colors[colorIndex][i],
                  width: 0.1,
                  opacity: 0.5,
                  curveness: 0.3
                }
              },
              zlevel: 2
            },
              {
              type: 'bar',
              data: props.barData[i],
              itemStyle: {
                normal: {
                  color: colors[colorIndex][i]
                }
              }
            }]
          })
        }
        options.value = _option
      }

      watch(() => props.loadFlag, () => {
        if (!props.loadFlag) {
          loading.value = false
          update()
        }
      })

      onMounted(update)
      return {
        loading,
        options
      }
    }
  }
</script>

<style lang="scss" scoped>
  .order-map {
    width: 100%;
    height: 100%;

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      font-size: 36px;
      background: rgb(48, 48, 48);
      color: #fff;
    }
  }
</style>
