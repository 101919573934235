<template>
  <div class="nt-map" v-if="!loading">
    <nt-echarts :options="options"/>
  </div>
  <div class="nt-map" v-else>
    <div class="loading-wrapper">{{ loadingText }}</div>
  </div>
</template>

<script>
  import { ref, onMounted, onUnmounted, watch } from 'vue'
  import echarts from 'echarts'
  import cloneDeep from 'lodash/cloneDeep'
  import NtEcharts from '@/components/NtEcharts/NtEcharts.vue'
  const ROOT_PATH = './'
  export default {
    name: 'YunnanMap3D',
    props: {
      mapData: Object,
      // centerMap: Array,
      loadFlag: {
        type: Boolean,
        default: true
      },
      centerMapValue: Object,
      centerMapDetail: Object
    },
    components: { NtEcharts },
    setup(props) {
      const options = ref({})
      const loadingText = ref('加载中.')
      const loading = ref(true)

      let timer = null
      let loadingTimer = null
      const update = () => {
        if (props.loadFlag) {
          return
        }
        // console.log(props.mapData)
        // console.log(props.centerMap)
        echarts.registerMap('yunnan', props.mapData) // 注册地图
        options.value = {
          title: {
            text: '全省人口数据地图',
            subtext: '数据由百度数据提供',
            left: '2%',
            top: '2%',
            textStyle: {
              color: '#4455c7',
              // color: 'rgb(116,166,49)',
              fontSize: 35,
              fontWeight: 700
            }
          },
          grid: { // 不起作用
            // top: -10
            left: '10%'
          },
          // visualMap: {
          //   show: true,
          //   max: 860,
          //   seriesIndex: [0],
          //   inRange: {
          //     // color: ['#a5dcf4', '#006edd']
          //     color: ['rgb(251,253,142)', 'rgb(116,166,49)']
          //   },
          //   // text: ['人口情况（万人）', 'Low']
          //   text: ['人口情况（万人）'],
          //   precision: 2,
          //   textStyle: {
          //     color: '#869f82',
          //     fontSize: 15
          //   }
          // },
          geo3D: [{
            map: 'yunnan', // 使用自定义地图
            // environment: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            //   offset: 0, color: '#00aaff' // 天空颜色
            // }, {
            //   offset: 0.7, color: '#998866' // 地面颜色
            // }, {
            //   offset: 1, color: '#998866' // 地面颜色
            // }], false)
            environment: `${ROOT_PATH}assets/star-bg.jpg`, // 背景环境贴图
            itemStyle: {
              color: '#2B5890',
              // color: 'transparent',
              // color: {
              //   //   // 径向渐变，前三个参数分别是圆心 x, y 和半径，取值同线性渐变
              //   type: 'radial',
              //   x: 1.5,
              //   y: 0.5,
              //   r: 0.8,
              //   colorStops: [{
              //     offset: 0, color: 'rgba(147,235,248,0)'
              //   }, {
              //     offset: 1, color: 'rgba(147,235,248,0.2)'
              //   }],
              //   global: false // 缺省为 false
              //   // 线性渐变，前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
              //   // type: 'linear',
              //   // x: 0,
              //   // y: 0,
              //   // x2: 0,
              //   // y2: 1,
              //   // colorStops: [{
              //   //   offset: 0, color: 'rgba(147,235,248,0)' // 0% 处的颜色
              //   // }, {
              //   //   offset: 1, color: 'rgba(147,235,248,0.2)' // 100% 处的颜色
              //   // }],
              //   // global: false // 缺省为 false
              //   // image: imageDom, // 支持为 HTMLImageElement, HTMLCanvasElement，不支持路径字符串
              //   // repeat: 'repeat' // 是否平铺，可以是 'repeat-x', 'repeat-y', 'no-repeat'
              // },
              opacity: 0.8,
              borderWidth: 0.4,
              borderColor: 'rgba(147,235,248,1)'
            },
            // top: '1%',
            boxWidth: 80, // 调节地图大小
            boxHeight: 5,
            regionHeight: 3,
            // groundPlane: {   // 地面平板
            //   show: true,
            //   color: '#8c8237'
            // },
            // instancing: true,
            label: {
              show: false,
              distance: 1, // ?
              // formatter: '{b}: {c}',
              textStyle: {
                color: '#fff', // 地图初始化区域字体颜色
                fontSize: 15,
                fontWeight: 10,
                opacity: 1, // 到底有没有？
                backgroundColor: 'rgba(0,23,11,0)'
                // backgroundColor: 'red'
              }
            },
            emphasis: {
              label: {
                show: true,
                distance: 10,
                textStyle: {
                  color: '#c6cbbc', // 显示字体颜色变淡
                  fontSize: 18 // 显示字体变大
                }
              },
              itemStyle: {
                // color: '#5ec9db', // 显示移入的区块变粉色
                color: '#5ec9db', // 显示移入的区块变色
                opacity: 0.8
              }
            },
            viewControl: {
              autoRotate: false,
              autoRotateAfterStill: 3,
              distance: 120,
              minAlpha: 5, // 上下旋转的最小 alpha 值。即视角能旋转到达最上面的角度。[ default: 5 ]
              maxAlpha: 90, // 上下旋转的最大 alpha 值。即视角能旋转到达最下面的角度。[ default: 90 ]
              minBeta: -360, // 左右旋转的最小 beta 值。即视角能旋转到达最左的角度。[ default: -80 ]
              maxBeta: 360, // 左右旋转的最大 beta 值。即视角能旋转到达最右的角度。[ default: 80 ]
              animation: true, // 是否开启动画。[ default: true ]
              animationDurationUpdate: 1000, // 过渡动画的时长。[ default: 1000 ]
              animationEasingUpdate: 'cubicInOut' // 过渡动画的缓动效果。[ default: cubicInOut ]
            },
            // shading: 'lambert',
            // lambertMaterial: {
            //   detailTexture: `${ROOT_PATH}assets/yn.png`, // 测试
            //   textureTiling: 3
            // },
            // shading: 'color',
            // colorMaterial: {
            //   detailTexture: `${ROOT_PATH}assets/yn.png`, // 测试
            //   textureTiling: 1
            // },
            light: {
              // 光照阴影
              main: {
                color: '#fff', // 光照颜色
                intensity: 1, // 光照强度
                shadow: true, // 是否显示阴影
                // shadowQuality: 'medium', // 阴影质量 ultra // 阴影亮度
                shadowQuality: 'high', // 阴影亮度
                alpha: 55,
                beta: 10
              },
              ambient: {
                intensity: 0.7
              }
            }
            // postEffect: {
            //   enable: true,
            //   bloom: {
            //     enable: true,
            //     intensity: 0.5
            //   },
            //   depthOfField: {
            //     enable: false,
            //     focalRange: 2,
            //     focalDistance: 5,
            //     blurRadius: 1,
            //     fstop: 2.8,
            //     quality: 'low'
            //   },
            //   screenSpaceAmbientOcclusion: {
            //     enable: true,
            //     radius: 2,
            //     // low, medium, high, ultra
            //     quality: 'medium',
            //     intensity: 1
            //   },
            //   screenSpaceReflection: {
            //     enable: false,
            //     quality: 'low',
            //     maxRoughness: 0.8
            //   },
            //   edge: {
            //     enable: false
            //   },
            //   FXAA: {
            //     enable: false
            //   }
            // }
          }],
          series: [
          {
            type: 'scatter3D',
            data: [],
            coordinateSystem: 'geo3D',
            symbolSize: 16,
            itemStyle: {
              color: '#feae21'
            },
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: function (parames) {
                  // console.log('警告', parames)
                  return `${parames.data.city}\n发生警告${parames.data.event}事故`
                },
                backgroundColor: 'rgba(254,174,33,.8)',
                color: '#f7fafb'
              },
              emphasis: {
                show: true
              }
            },
            animation: false
            // blendMode: 'lighter'
          },
            {
              type: 'scatter3D',
              data: [],
              coordinateSystem: 'geo3D',
              symbolSize: 16,
              itemStyle: {
                color: '#e93f42'
              },
              label: {
                normal: {
                  show: true,
                  position: 'top',
                  formatter: function (parames) {
                    // console.log('重大', parames)
                    // return `{title|${parames.data.city}}\n{content|发生重大${parames.data.event}事故}`
                    return `${parames.data.city}\n发生重大${parames.data.event}事故`
                  },
                  backgroundColor: 'rgba(233,63,66,.9)',
                  // padding: [0, 0],
                  // borderRadius: 3,
                  lineHeight: 32,
                  color: '#f7fafb'
                  // rich: {
                  //   title: {
                  //     padding: [0, 10, 10, 10],
                  //     color: '#fff'
                  //   },
                  //   content: {
                  //     padding: [10, 10, 0, 10],
                  //     color: '#fff'
                  //   }
                  // }
                },
                emphasis: {
                  show: true
                }
              }
            },
            {
              type: 'scatter3D',
              data: [],
              coordinateSystem: 'geo3D',
              symbolSize: 16,
              itemStyle: {
                color: '#08baec'
              },
              label: {
                normal: {
                  show: true,
                  position: 'top',
                  formatter: function (parames) {
                    // console.log('严重', parames)
                    // return `{title|${parames.data.city}}\n{content|发生严重${parames.data.event}事故}`
                    return `${parames.data.city}\n发生严重${parames.data.event}事故`
                  },
                  backgroundColor: 'rgba(8, 186, 236, .9)',
                  // padding: [0, 0],
                  // borderRadius: 3,
                  lineHeight: 32,
                  color: '#f7fafb'
                  // rich: {
                  //   title: {
                  //     padding: [0, 10, 10, 10],
                  //     color: '#fff'
                  //   },
                  //   content: {
                  //     padding: [10, 10, 0, 10],
                  //     color: '#fff'
                  //   }
                  // }
                },
                emphasis: {
                  show: true
                }
              }
            }]
        }

        let eventIndex = 0

        timer = setInterval(() => {
          const _options = cloneDeep(options.value)
          // 初始化数据
          for (let i = 0; i < 3; i++) {
            _options.series[i].data = []
          }
          // console.log('timer', props.centerMapDetail)

          // 顺序显示事件
          _options.series[props.centerMapDetail[eventIndex].type - 1].data = [{
            city: props.centerMapDetail[eventIndex].events.city,
            value: props.centerMapDetail[eventIndex].events.value.concat(50),
            event: props.centerMapDetail[eventIndex].events.event
          }]
          eventIndex += 1
          if (eventIndex >= props.centerMapDetail.length) {
            eventIndex = 0
          }
          // Todo 随机显示事件
          // Todo 按照事件级别显示事件
          options.value = _options
        }, 2000)
      }

      watch(() => props.loadFlag, () => {
        if (!props.loadFlag) {
          update()
          loading.value = false
          loadingTimer && clearInterval(loadingTimer)
        }
      })

      onMounted(() => {
        update()
        loadingTimer = setInterval(() => {
          if (loadingText.value === '加载中......') {
            loadingText.value = '加载中.'
          } else {
            loadingText.value += '.'
          }
        }, 200)
      })

      onUnmounted(() => {
        timer && clearInterval(timer)
        loadingTimer && clearInterval(loadingTimer)
      })

      return {
        options,
        loadingText,
        loading
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nt-map {
    width: 100%;
    height: 100%;

    .loading-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      color: #000;
    }
  }
</style>
