<template>
  <div class="home" >
    <yun-nan-map
      :mapData="mapData"
      :centerMapValue="centerMapValue"
      :travelMapDetail="travelMapDetail"
      :loadFlag="loading"/>
  </div>
</template>

<script>
  import YunNanMap from '@/components/YunNanMap/index'
  import useYnMap from '../hooks/useYnMap'
  export default {
    name: 'TestMapYn',
    components: { YunNanMap },
    setup () {
      return {
        ...useYnMap()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
