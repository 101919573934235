<template>
  <div class="home" >
    <tenant-map
      :mapData="mapData"
      :category="category"
      :categoryData="categoryData"
      :barData="barData"
      :lineData="lineData"
      :effectData="effectData"
      :loadFlag="loadFlag" />
  </div>
</template>

<script>
  import TenantMap from '@/components/TenantMap/index'
  import useTenantMapData from '../hooks/useTenantMapData'
  export default {
    name: 'TestTenantMap',
    components: { TenantMap },
    setup () {
      return {
        ...useTenantMapData()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    /*background: rgb(29, 29, 29);*/
  }
</style>
