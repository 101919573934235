<template>
  <div class="home" >
    <work-list :data="workListData" />
  </div>
</template>

<script>
  import WorkList from '../components/WorkList/index'
  import useScreenData from '../hooks/useScreenData'

  export default {
    name: 'TestWorkList',
    components: { WorkList },
    setup () {
      return {
        ...useScreenData()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
