import { ref, onMounted, onUnmounted } from 'vue'

/* eslint-disable */
/** 全市人口数  主城区全面挤在一起，只有选其他区了 */
const d1 = {
  '东川区': 114.4,
  '嵩明县': 99.6,
  '西山区': 96.6,
  '官渡区': 161.1,
  '呈贡区': 65.1
}
/** 全市旅游情况  */
const travel = [{
    name: 'travel1',
    from: '东川区',
    to: '嵩明县',
    value: 0.1
  },
  {
    name: 'travel2',
    from: '东川区',
    to: '呈贡区',
    value: 0.05
  },
  {
    name: 'travel3',
    from: '东川区',
    to: '呈贡区',
    value: 0.2
  }, {
    name: 'travel4',
    from: '呈贡区',
    to: '嵩明县',
    value: 0.2
  },
  {
    name: 'travel5',
    from: '呈贡区',
    to: '富民县',
    value: 2
  },
  {
    name: 'travel6',
    from: '呈贡区',
    to: '宜良县',
    value: 0.2
  },
  {
    name: 'travel7',
    from: '呈贡区',
    to: '石林彝族自治县',
    value: 0.2
  },
  {
    name: 'travel8',
    from: '呈贡区',
    to: '晋宁区',
    value: 0.2
  },
  {
    name: 'travel9',
    from: '呈贡区',
    to: '禄劝彝族苗族自治县',
    value: 0.2
  }]
/* eslint-enable */

function getMapValue(cityName, center) {
  let value = []
  center.forEach(item => {
    if (cityName === item.key) {
      value = item.value
    }
  })
  return value
}

export default function () {
  /** geo坐标数据，从第三方获得 */
  const mapData = ref(null)
  /** 城市中心点坐标 */
  /**  数组【key：城市名；value：中心坐标 */
  // const centerMap = ref([])
  /** 城市中心点事件 */
  /**  对象 {name：城市名称；value：【坐标，事件数】} effectScatter */
  const centerMapValue = ref(null)
  /** 2d飞线 */
  /**  对象 [{coord: from坐标，value: value]}，{coord: [to坐标]]} */
  const travelMapDetail = ref(null)
  /** 3d飞线 */
  /**  对象 [{coords: [[from坐标]，[to坐标]]}，{value: value},{name: name} */
  const fightMapDetail = ref(null)

  const loading = ref(true)
  let task

  const update = () => {
    fetch('./data/530100_full.json')
      .then(response => response.json())
      .then(data => {
        mapData.value = data
        // console.log(data)
        const center = []
        const value = []
        data.features.forEach(item => {
          if (item.properties) {
            // console.log(item.properties.name)
            const geoCoord = item.properties.center
            let mapValue = d1[item.properties.name]
            if (d1[item.properties.name]) {
              mapValue = d1[item.properties.name]
            } else {
              mapValue = 0
            }
            center.push({
              key: item.properties.name,
              value: item.properties.center
            })
            value.push({
              name: item.properties.name,
              value: geoCoord.concat(mapValue)
            })
          }
        })
        // centerMap.value = center
        centerMapValue.value = value

        const _travelMapDetail = []
        travel.forEach(item => {
          _travelMapDetail.push([
              {
                coord: getMapValue(item.from, center),
                value: item.value
              }, {
                coord: getMapValue(item.to, center)
              }
            ])
        })
        travelMapDetail.value = _travelMapDetail

        const _fightMapDetail = []
        travel.forEach(item => {
          _fightMapDetail.push({
              coords: [getMapValue(item.from, center), getMapValue(item.to, center)],
              name: item.name,
              value: item.value
          })
        })
        fightMapDetail.value = _fightMapDetail
        console.log('fightMapDetail', fightMapDetail.value)
        loading.value = false
      })
  }

  onMounted(() => {
    update()
  })

  onUnmounted(() => {
    task && clearInterval(task)
  })

  return {
    mapData,
    centerMapValue,
    travelMapDetail,
    fightMapDetail,
    loading
  }
}
