<template>
  <div class="nt-map">
    <nt-echarts :options="options"/>
  </div>
</template>

<script>
  import { ref, onMounted, onUnmounted, watch } from 'vue'
  import echarts from 'echarts'
  import NtEcharts from '@/components/NtEcharts/NtEcharts.vue'

  export default {
    name: 'KunmingMapTest',
    props: {
      mapData: Object,
      loadFlag: {
        type: Boolean,
        default: true
      }
    },
    components: { NtEcharts },
    setup(props) {
      const options = ref({})
      const update = () => {
        if (props.loadFlag) {
          return
        }
        echarts.registerMap('china', props.mapData) // 注册地图
        options.value = {
          title: {
            text: '中华人民共和国地图',
            subtext: '数据由百度数据提供，李承宸',
            left: '2%',
            top: '2%',
            textStyle: {
              color: 'red',
              // color: '#ddd',
              fontSize: 35,
              fontWeight: 700
            }
          },
          geo: {
            map: 'china',
            zoom: 1.1,
            roam: false,
            scaleLimit: {
              min: 0.5,
              max: 3
            },
            // center: [113.83531246, 34.0267395887], // 视角的中心点
            itemStyle: {
              normal: {
                // borderColor: 'rgba(147,235,248,1)',
                borderColor: 'yellow',
                borderWidth: 0.5,
                areaColor: {
                  // 径向渐变，前三个参数分别是圆心 x, y 和半径，取值同线性渐变
                  // type: 'radial',
                  // x: 0.5,
                  // y: 0.5,
                  // r: 0.8,
                  // colorStops: [{
                  //   offset: 0, color: 'rgba(147,235,248,0)'
                  // }, {
                  //   offset: 1, color: 'rgba(147,235,248,0.2)'
                  // }],
                  // global: false // 缺省为 false
                  // 线性渐变，前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgba(147,235,248,0)' // 0% 处的颜色
                  }, {
                    offset: 1, color: 'rgba(147,235,248,0.2)' // 100% 处的颜色
                  }],
                  global: false // 缺省为 false
                  // image: imageDom, // 支持为 HTMLImageElement, HTMLCanvasElement，不支持路径字符串
                  // repeat: 'repeat' // 是否平铺，可以是 'repeat-x', 'repeat-y', 'no-repeat'
                },
                shadowColor: 'rgba(128,217,248,1)',
                shadowOffsetX: -2,
                shadowOffsetY: 2,
                // 图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果。
                shadowBlur: 10
              },
              emphasis: {
                areaColor: '#389BB7',
                borderWidth: 0
              }
            },
            label: {
              emphasis: {
                show: false
              }
            }
          },
          series: []
        }
      }

      watch(() => props.loadFlag, () => {
        if (!props.loadFlag) {
          update()
        }
      })

      onMounted(() => {
        update()
      })

      onUnmounted(() => {

      })

      return {
        options
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nt-map {
    width: 100%;
    height: 100%;

    .loading-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      color: #fff;
      background: rgb(48, 48, 48);
    }
  }
</style>
