<template>
  <div class="home">
    <liquid-fill :data="data"></liquid-fill>
  </div>
</template>

<script>
    import LiquidFill from '@/components/LiquidFill'
    import { ref } from 'vue'

    export default {
      name: 'TestliquidFill',
      components: { LiquidFill },
      setup() {
        const data = ref([0.78, 0.5, 0.4, 0.3])
        return {
          data
        }
      }
    }
</script>

<style scoped>
  .home{
    width: 100%;
    height: 100%;
  }
</style>
