<template>
  <div class="home" style="width:100%;height:100%">
    <rotating-earth />
  </div>
</template>

<script>
  import RotatingEarth from '@/components/RotatingEarth/index'
  export default {
    name: 'TestMap',
    components: { RotatingEarth },
    setup () {
      return {
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    background: white;
    /*background: rgb(29, 29, 29);*/
  }
</style>
