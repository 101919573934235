<template>
  <div class="home" >
    <total-Tenant
      :tenantNum="tenantNum"
      :tenantGroups="tenantGroups"
      :growthTenantLastDay="growthTenantLastDay"
      :growthTenantLastMonth="growthTenantLastMonth"
      :listSum="listSum"
      :listTenant="listTenant"
      :data="tenantCostData"
    />
  </div>
</template>

<script>
  import TotalTenant from '@/components/TotalTenant/index'
  import useScreenData from '../hooks/useScreenData'

  export default {
    name: 'TestTotalTenant',
    components: { TotalTenant },
    setup () {
      return {
        ...useScreenData()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
