<template>
  <div class="warning-radar">
    <div class="warning-radar-inner">
      <nt-echarts :options="options" />
    </div>
    <div class="warning-radar-text-wrapper">
      <div class="warning-detail">
        <div class="point" :style="{background: 'red'}" />
        <div class="warning-radar-text">紧急告警：
          <span class="warning-radar-value">
            <count-to
              :startVal="0"
              :endVal="22"
              :duration="1000"
            />
          </span>
        </div>
      </div>
      <div class="warning-detail">
        <div class="point" :style="{background: 'orange'}" />
        <div class="warning-radar-text">重要告警：
          <span class="warning-radar-value">
            <count-to
              :startVal="0"
              :endVal="32"
              :duration="1000"
            />
          </span>
        </div>
      </div>
      <div class="warning-detail">
        <div class="point" :style="{background: 'blue'}" />
        <div class="warning-radar-text">
          轻微告警：
          <span class="warning-radar-value">
          <count-to
            :startVal="0"
            :endVal="102"
            :duration="1000"
          />
        </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { onMounted, ref, watch } from 'vue'
  import NtEcharts from '@/components/NtEcharts/NtEcharts'
  import countTo from '../VueCountTo/vue-countTo'
  export default {
    name: 'WarningRadar',
    props: {
      data: Object
    },
    components: { countTo, NtEcharts },
    setup (props) {
      const options = ref(null)
      const update = () => {
        if (props.data) {
          const { indicator, data } = props.data
          options.value = {
            title: {
              text: '平台告警',
              textStyle: {
                color: '#fff',
                fontSize: '32'
              },
              subtext: 'Warnings',
              subtextStyle: {
                color: '#fff',
                fontSize: '16'
              },
              top: 15,
              left: 15
            },
            radar: {
              name: {},
              indicator
            },
            series: [{
              name: '运营指标',
              type: 'radar',
              data
            }],
            tooltip: {}
          }
        }
      }

      onMounted(() => {
        update()
      })

      watch(() => props.data, () => {
        update()
      })

      return {
        options
      }
    }
  }
</script>

<style lang="scss" scoped>
  .warning-radar {
    display: flex;
    width: 100%;
    height: 100%;
    /*background: rgb(29, 29, 29);*/
    background: rgb(43, 44, 46);
    .warning-radar-inner {
      width: 60%;
      height: 100%;
      background: rgba(255, 255, 255, .05);
    }
    .warning-radar-text-wrapper{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      margin-top: 20px;
      .warning-detail{
        display: flex;
        margin-left: 20px;
        .point {
          width: 10px;
          height: 10px;
          margin-top: 50px;
          border-radius: 50%;
        }
        .warning-radar-text{
          margin-left: 20px;
          font-size: 24px;
          .warning-radar-value{
            font-size: 58px;
          }
        }
      }
    }
  }
</style>
