<template>
  <div class="home" >
    <order-map
      :mapData="mapData"
      :category="category"
      :categoryData="categoryData"
      :barData="barData"
      :lineData="lineData"
      :effectData="effectData"
      :loadFlag="loadFlag" />
  </div>
</template>

<script>
  import OrderMap from '@/components/OrderMap/indexMap'
  import useMapData from '../hooks/useMapData'
  export default {
    name: 'TestOrderMap',
    components: { OrderMap },
    setup () {
      return {
        ...useMapData()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    /*background: rgb(29, 29, 29);*/
  }
</style>
