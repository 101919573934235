<template>
  <div class="iconwrapper" :style="{...style}" >
    <svg class="icon" >
      <use :href="iconName"></use>
    </svg>
  </div>
</template>
<script>
  export default {
    name: 'NtIcon',
    props: {
      name: String,
      prefix: {
        type: String,
        default: 'icon-'
      },
      style: Object
    },
    setup(ctx) {
      const iconName = `#${ctx.prefix}${ctx.name}`
      return {
        iconName
      }
    }
  }
</script>
<style lang="scss" scoped>
  .iconwrapper{
    display: inline-block;
  }
  .icon {
    width: 100%;
    height: 100%;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
</style>
