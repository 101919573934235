<template>
  <div class="total-tenant">
    <div class="text-wrapper">
      <div class="title-wrapper">
        <div class="title">租户</div>
        <div class="sub-title">Tenant Total Count</div>
      </div>
      <div class="total">
        <span class="tenant-orgs">多级组织</span>
        <count-to :startVal="startVal" :endVal="tenantNum" :duration="1000"/>
        <span class="dev-unit">个</span>
        <span class="tenant-users">用户数量</span>
        <count-to :startVal="startVal" :endVal="tenantGroups" :duration="1000"/>
        <span class="dev-unit">个</span>
      </div>
    </div>
    <div class="percent-wrapper">
      <div class="percent-text">
        <span class="percent-text-1">
          每日增长率:
          <count-to
            :start-val="startPercent"
            :end-val="growthTenantLastDay"
            :duration="1000"
            :decimals="2"
            suffix="%"
          />
        </span>
        <span class="percent-text-2">
        每月增长率:
        <count-to
          :start-val="startPercent2"
          :end-val="growthTenantLastMonth"
          :duration="1000"
          :decimals="2"
          suffix="%"
        />
        </span>
      </div>
      <div class="item-wrapper">
        <div class="tenant-cost">
          <nt-echarts :options="options1"></nt-echarts>
        </div>
        <div class="tenant-top10">
          <nt-echarts :options="options2"></nt-echarts>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { onMounted, ref, watch } from 'vue'
  import countTo from '../VueCountTo/vue-countTo'
  import NtEcharts from '../NtEcharts/NtEcharts'
  const color = ['rgb(176,207,120)', 'rgb(157,195,91)', 'rgb(131,167,72)', 'rgb(99, 169, 0)']

  export default {
    name: 'totalHost',
    props: {
      tenantNum: {
        type: Number,
        default: 0
      },
      tenantGroups: {
        type: Number,
        default: 0
      },
      growthTenantLastDay: {
        type: Number,
        default: 0
      },
      growthTenantLastMonth: {
        type: Number,
        default: 0
      },
      listSum: {
        type: Array
      },
      listTenant: {
        type: Array
      },
      data: Object
    },
    components: { NtEcharts, countTo },
    setup(props) {
      const startVal = ref(0)
      const startPercent = ref(0)
      const startPercent2 = ref(0)
      const refData = ref([])
      const options1 = ref(null)
      const options2 = ref(null)

      watch(() => props.tenantNum, (nextValue, prevValue) => {
        startVal.value = prevValue
        update()
      })
      const updateChart = () => {
        options1.value = {
          title: {
            show: true,
            text: '租户使用资源计量计费分析',
            textStyle: {
              color: '#ddd'
            },
            left: '30%'
          },
          // 位置
          grid: {
            left: '0%',
            right: '90%',
            bottom: '0%',
            top: '0%'
          },
          legend: {
            show: true,
            orient: 'vertical',
            right: '30%',
            bottom: '50%',
            textStyle: {
              color: '#eee'
            }
          },
          series: [{
            name: '费用总数',
            type: 'pie',
            data: refData.value,
            roseType: 'radius',
            label: { show: false },
            emphasis: {
              itemStyle: {
                color: 'rgb(140,251,182)'
              }
            },
            radius: '80%',
            color,
            selectedMode: 'multiple',
            selectedOffset: 10,
            center: ['30%', '40%'],
            clockwise: true
          }]
        }
        // console.log('data', refData.value)
        options2.value = {
          title: {
            show: true,
            text: '租户虚拟机资源使用数量TOP10',
            textStyle: {
              color: '#ddd'
            },
            left: '20%'
          },
          tooltip: {
            trigger: 'axis'
          },
          // 位置
          grid: {
            left: '0%',
            right: '10%',
            bottom: '10%',
            top: '10%',
            containLabel: true
          },
          // x轴设置
          xAxis: {
            type: 'value',
            show: false
          },
          // y轴设置
          yAxis: {
            type: 'category',
            data: props.listTenant,
            // inverse: true,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: true,
              color: '#E1EEFF',
              fontFamily: 'PingFang',
              margin: 10,
              width: 60,
              // align: 'left'
              overflow: 'truncate',
              formatter: function (value, index) {
                const ind = index + 1
                if (ind === props.listTenant.length) {
                  return '{one|' + (props.listTenant.length - index) + '  } {a|' + value + '}'
                } else if (ind + 1 === props.listTenant.length) {
                  return '{two|' + (props.listTenant.length - index) + ' } {b|' + value + '}'
                } else if (ind + 2 === props.listTenant.length) {
                  return ('{three|' + (props.listTenant.length - index) + ' } {c|' + value + '}')
                }
                if (props.listTenant.length - index > 9) {
                  return ('{five|' + (props.listTenant.length - index) + '} {d|' + value + '}')
                }
                return '{four|' + (props.listTenant.length - index) + '} {d|' + value + '}'
              },
              rich: {
                a: {
                  color: '#59c9f9'
                },
                b: {
                  color: '#59c9f9'
                },
                c: {
                  color: '#59c9f9'
                },
                d: {
                  color: '#59c9f9'
                },
                // 第一名
                one: {
                  backgroundColor: '#E86452',
                  color: 'white',
                  width: 12,
                  height: 16,
                  padding: [1, 0, 0, 5],
                  borderRadius: 10,
                  fontSize: 11
                },
                // 第二名
                two: {
                  backgroundColor: '#FF9845',
                  color: 'white',
                  width: 12,
                  height: 16,
                  padding: [1, 0, 0, 5],
                  borderRadius: 10,
                  fontSize: 11
                },
                // 第三名
                three: {
                  backgroundColor: '#F6BD16',
                  color: 'white',
                  width: 12,
                  height: 16,
                  padding: [1, 0, 0, 5],
                  borderRadius: 10,
                  fontSize: 11
                },
                // 一位数
                four: {
                  backgroundColor: 'rgba(0,0,0,0.15)',
                  color: 'white',
                  width: 12,
                  height: 16,
                  padding: [1, 0, 0, 5],
                  borderRadius: 10,
                  fontSize: 11
                },
                // 两位数
                five: {
                  backgroundColor: 'rgba(0,0,0,0.15)',
                  color: 'white',
                  width: 16,
                  height: 16,
                  padding: [1, 0, 0, 1],
                  borderRadius: 10,
                  fontSize: 11
                }
              }
            }
          },
          series: [{
            type: 'bar',
            showBackground: true,
            label: {
              show: true,
              position: 'right',
              color: '#eee'
            },
            barWidth: 15,
            itemStyle: {
              color: 'rgb(202,252,137)'
            },
            data: props.listSum
          }]
        }
      }
      const update = (newData) => {
        // console.log(newData)
        // console.log('update', refData)
        // console.log(newData.devices)
        if (!newData || !newData.projects) {
          return
        }
        if (refData.value.length > 0) {
          refData.value = newData.projects.map((item, index) => ({
            startValue: refData.value[index].value,
            value: item.value,
            name: item.key,
            color: color[index]
          }))
        } else {
          refData.value = newData.projects.map((item, index) => ({
            startValue: 0,
            value: item.value,
            name: item.key,
            color: color[index]
          }))
        }
        // startNum.value = num.value
        // num.value = +newData.totalProjects
        updateChart()
      }
      watch(() => props.data, (nextValue, prevValue) => {
        // console.log('watch', nextValue)
        update(nextValue)
      })
      onMounted(() => {
        update(props.data)
      })
      return {
        startVal,
        startPercent,
        startPercent2,
        options1,
        options2
      }
    }
  }
</script>

<style lang="scss" scoped>
  .total-tenant {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /*background: rgb(66, 68, 70);*/
    background: rgb(43, 44, 46);
    box-shadow: 0 10px 10px rgba(0, 0, 0, .3);
    padding: 40px 40px;
    /*margin-top: 40px;*/
    box-sizing: border-box;

    .text-wrapper {
      display: flex;

      .title-wrapper {
        .title {
          font-size: 32px;
        }

        .sub-title {
          font-size: 16px;
          letter-spacing: 1px;
          margin-top: 10px;
        }
      }

      .total {
        margin-left: 40px;
        font-family: DIN;
        font-size: 58px;
        font-weight: bolder;
        letter-spacing: 2px;
        /*padding: 10px 0;*/
        .tenant-orgs{
          margin-left: 20px;
          font-size: 20px;
        }
        .tenant-users{
          margin-left: 20px;
          font-size: 20px;
        }
        .dev-unit {
          /*margin-left: 20px;*/
          font-size: 20px;
        }
      }
    }

    .percent-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .percent-text {
        font-size: 28px;
        font-family: DIN;
        letter-spacing: 2px;
        padding-bottom: 10px;

        .percent-text-1 {
          color: rgb(197, 251, 121);
        }

        .percent-text-2 {
          color: rgb(99, 169, 0);
          margin-left: 10px;
        }
      }
      .item-wrapper{
        display: flex;
        align-items: center;
        margin-top: 30px;
        .tenant-cost{
          width: 50%;
          height: 400px;
        }
        .tenant-top10 {
          flex: 1;
          /*width: 100%;*/
          height: 400px;
        }
      }
    }
  }
</style>
