<template>
  <div :class="[className, 'echarts']"></div>
</template>

<script>
  import { watch, onMounted } from 'vue'
  import Echarts from 'echarts'
  import { v4 as uuidv4 } from 'uuid'

  export default {
    name: 'NtEcharts',
    props: {
      options: Object,
      theme: [String, Object]
    },
    setup(ctx) {
      let dom
      let chart
      const className = `echarts${uuidv4()}`

      const initChart = () => {
        if (!chart) {
          dom = document.getElementsByClassName(className)[0]
          // chart = Echarts.init(dom, ctx.theme, { renderer: 'svg' })
          chart = Echarts.init(dom, ctx.theme)
        }
        if (ctx.options) {
          chart.setOption(ctx.options)
          // chart.on('click', (params) =>
          //   console.log(params)
          // )
        }
      }
      onMounted(() => {
        initChart()
      })
      watch(() => ctx.options, () => {
        initChart()
      })
      return {
        className
      }
    }
  }
</script>

<style lang="scss" scoped>
  .echarts {
    width: 100%;
    height: 100%;
  }
</style>
