<template>
  <div class="home">
    <word-cloud :data="data"></word-cloud>
  </div>
</template>

<script>
    import WordCloud from '@/components/WordCloud'
    import { ref } from 'vue'

    export default {
      name: 'TestWordCloud',
      components: { WordCloud },
      setup() {
        const data = ref([{
          name: '南天',
          value: 200
        }, {
          name: '数据可视化',
          value: 50
        }, {
          name: '智慧城市',
          value: 80
        }, {
          name: '能投',
          value: 100
        }, {
          name: '政企业务',
          value: 30
        }, {
          name: '智慧交通',
          value: 180
        }])
        return {
          data
        }
      }
    }
</script>

<style scoped>
  .home{
    width: 100%;
    height: 100%;
    background: white;
  }
</style>
