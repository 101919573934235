<template>
  <div class="home" >
    <total-dial
      :rate="cpuRate"
      name="CPU平均使用率"
      name2="CPU使用率峰值"
      legendData="CPU使用率"
      :axisData="cpuAxisData"
      :data="cpuUsage"
    />
  </div>
</template>

<script>
  import TotalDial from '@/components/TotalDial/index'
  import useScreenData from '../hooks/useScreenData'

  export default {
    name: 'TestTotalDial',
    components: { TotalDial },
    setup () {
      return {
        ...useScreenData()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
