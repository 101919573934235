<template>
  <div class="nt-map" v-if="!loading">
    <nt-echarts :options="options"/>
  </div>
  <div class="nt-map" v-else>
    <div class="loading-wrapper">{{ loadingText }}</div>
  </div>
</template>

<script>
  import { ref, onMounted, onUnmounted, watch } from 'vue'
  import echarts from 'echarts'
  import NtEcharts from '@/components/NtEcharts/NtEcharts.vue'

  const ROOT_PATH = './'
  export default {
    name: 'KunmingMap',
    props: {
      mapData: Object,
      // centerMap: Array,
      loadFlag: {
        type: Boolean,
        default: true
      },
      centerMapValue: Object,
      fightMapDetail: Object
    },
    components: { NtEcharts },
    setup(props) {
      const options = ref({})
      const loadingText = ref('加载中.')
      const loading = ref(true)

      const colors = [
        ['#1DE9B6',
          '#1DE9B6',
          '#FFDB5C',
          '#FFDB5C',
          '#04B9FF',
          '#04B9FF'],
        ['#1DE9B6', '#F46E36', '#04B9FF', '#5DBD32', '#FFC809', '#FB95D5', '#BDA29A', '#6E7074', '#546570', '#C4CCD3'],
        ['#37A2DA', '#67E0E3', '#32C5E9', '#9FE6B8', '#FFDB5C', '#FF9F7F', '#FB7293', '#E062AE', '#E690D1', '#E7BCF3', '#9D96F5', '#8378EA', '#8378EA'],
        ['#DD6B66', '#759AA0', '#E69D87', '#8DC1A9', '#EA7E53', '#EEDD78', '#73A373', '#73B9BC', '#7289AB', '#91CA8C', '#F49F42']
      ]
      const colorIndex = 1

      let loadingTimer = null
      const update = () => {
        if (props.loadFlag) {
          return
        }
        options.value = {
          title: {
            text: '全市人口数据地图',
            subtext: '数据由百度数据提供',
            left: '2%',
            top: '2%',
            textStyle: {
              color: '#ddd',
              fontSize: 35,
              fontWeight: 700
            }
          },
          geo3D: {
            map: 'kunming',
            environment: `${ROOT_PATH}assets/star-bg.jpg`, // 背景环境贴图
            itemStyle: {
              // color: 'yellow',
              color: '#2B5890',
              opacity: 0.8,
              borderWidth: 0.4,
              borderColor: 'rgba(147,235,248,1)'
            },
            boxWidth: 45,
            label: {
              show: false,
              textStyle: {
                color: '#fff', // 地图初始化区域字体颜色
                fontSize: 15,
                fontWeight: 10,
                opacity: 1, // 到底有没有？
                backgroundColor: 'rgba(0,23,11,0)'
                // backgroundColor: 'red'
              },
              emphasis: {
                show: false
              }
            }
          },
          series: [
          {
            type: 'scatter3D',
            coordinateSystem: 'geo3D',
            data: props.centerMapValue,
            // 图标大小
            symbolSize: function (val) {
                // console.log('val', val)
                return val[2] / 10
            },
            label: {
              normal: {
                show: false,
                position: 'right',
                formatter: function (params) {
                  return params.data.name
                }
              }
            },
            itemStyle: {
              normal: {
                color: colors[colorIndex][1],
                shadowColor: colors[colorIndex][1],
                shadowBlur: 10
              }
            },
            zlevel: -20
          },
          {
            type: 'lines3D',
            coordinateSystem: 'geo3D',
            polyline: false,
            data: props.fightMapDetail,
            effect: {
              show: true,
              trailWidth: 3,
              trailLength: 0.8,
              trailOpacity: 0.5,
              trailColor: '#FFDB5C'
            },
            lineStyle: {
              width: 20,
              color: '#DD6B66',
              opacity: 0.2
            }
          }]
        }
      }

      watch(() => props.loadFlag, () => {
        if (!props.loadFlag) {
          echarts.registerMap('kunming', props.mapData) // 注册地图
          update()
          loading.value = false
          loadingTimer && clearInterval(loadingTimer)
        }
      })

      onMounted(() => {
        // console.log('1', props.mapData)
        // echarts.registerMap('kunming', props.mapData) // 注册地图
        update()
        loadingTimer = setInterval(() => {
          if (loadingText.value === '加载中......') {
            loadingText.value = '加载中.'
          } else {
            loadingText.value += '.'
          }
        }, 200)
      })

      onUnmounted(() => {
        loadingTimer && clearInterval(loadingTimer)
      })

      return {
        options,
        loadingText,
        loading
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nt-map {
    width: 100%;
    height: 100%;

    .loading-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      color: #fff;
      background: rgb(48, 48, 48);
    }
  }
</style>
