<template>
  <div class="wordCloud">
    <nt-echarts :options="options"></nt-echarts>
  </div>
</template>

<script>
  import 'echarts-wordcloud'
  import NtEcharts from '@/components/NtEcharts/NtEcharts'
  import { ref } from 'vue'

  export default {
    name: 'NtWordCloud',
    props: {
      data: Array
    },
    components: { NtEcharts },
    setup(props) {
      const options = ref({})
      options.value = {
          series: [{
            type: 'wordCloud',
            data: props.data,
            shape: 'circle',
            textStyle: {
              normal: {
                fontFamily: 'sans-serif',
                fontWeight: 'bold',
                // Color can be a callback function or a color string
                color: function () {
                  // Random color
                  return 'rgb(' + [
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160)
                  ].join(',') + ')'
                }
              },
              emphasis: {
                // focus: 'self',
                textShadowBlur: 10,
                textShadowColor: '#333'
              }
            }
          }]
      }
      return {
        options
      }
    }
  }
</script>

<style scoped>
  .wordCloud{
    width: 100%;
    height: 100%;
  }
</style>
