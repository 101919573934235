<template>
  <div class="home" >
    <nt-video-play :videoUrl="videoUrl" :poster="poster" />
  </div>
</template>

<script>
  import NtVideoPlay from '@/components/VideoPlay/index'
  import useScreenData from '../hooks/useScreenData'
  export default {
    name: 'TestNtVideoPlay',
    components: { NtVideoPlay },
    setup () {
      return {
        ...useScreenData()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
