<template>
  <div class="liquidFill">
    <nt-echarts :options="options"></nt-echarts>
  </div>
</template>

<script>
  import 'echarts-liquidfill'
  import NtEcharts from '@/components/NtEcharts/NtEcharts'
  import { ref } from 'vue'

  export default {
    name: 'NtliquidFill',
    props: {
      data: Array
    },
    components: { NtEcharts },
    setup(props) {
      const options = ref({})
      options.value = {
        series: [{
          type: 'liquidFill',
          data: props.data,
          color: ['red', 'yellow', 'green', '#45BDFF'],
          itemStyle: {
            opacity: 0.3
          }
        }]
      }
      return {
        options
      }
    }
  }
</script>

<style scoped>
  .liquidFill{
    width: 100%;
    height: 100%;
  }
</style>
