<template>
    <div class="nt-fly-box" :ref="refName">
        <svg :width="width" :height="height" >
            <defs>
                <path
                    :id = "pathId"
                    :d="path"
                    fill="none" >
                </path>
                <radialGradient :id="radialId"
                     r="50%"
                     cx="50%"
                     cy="50%"
                     fx="100%"
                     fy="50%"
                     >
                    <stop offset="0%" stop-color="#fff"  stop-opacity="1"/>
                    <stop offset="100%" stop-color="#fff" stop-opacity="0" />
                </radialGradient>
                <mask :id="maskId">
                    <circle :r="starLength" cx="0" cy="0" :fill="`url(#${radialId})`" >
                        <animateMotion
                            :dur="`${duration}s`"
                            :path="path"
                            rotate="auto"
                            repeatCount="indefinite"
                        />
                    </circle>
                </mask>
            </defs>
            <use
                :href="`#${pathId}`"
                stroke-width="1"
                :stroke="lineColor"
            ></use>
            <use
                :href="`#${pathId}`"
                stroke-width="3"
                :stroke="starColor"
                :mask="`url(#${maskId})`"
            ></use>
        </svg>
        <div class="nt-fly-box-content" >
            <slot></slot>
        </div>
    </div>
</template>
<script>
import { computed, ref, onMounted, getCurrentInstance } from 'vue'
import { v4 as uuidv4 } from 'uuid'

export default {
    name: 'NtFlyBox',
    props: {
        lineColor: {
            type: String,
            default: '#235fa7'
        },
        starColor: {
            type: String,
            default: '#4fd2dd'
        },
        starLength: {
            type: [Number, String],
            default: 50
        },
        duration: {
            type: [Number, String],
            default: 2
        }
    },
    setup(ctx) {
        const uuid = uuidv4()
        // console.log(uuid)
        const width = ref(0)
        const height = ref(0)
        const refName = 'ntFlyBox'
        const pathId = `${refName}-path-${uuid}`
        const radialId = `${refName}-radial-${uuid}`
        const maskId = `${refName}-mask-${uuid}`

        const path = computed(() =>
            `M5 5 L${width.value - 5} 5 L${width.value - 5} ${height.value - 5} L5 ${height.value - 5} Z`
        )

        const init = () => {
            // const instance = getCurrentInstance()
            // const dom = instance.ctx.$refs[refName]
            const { refs } = getCurrentInstance()
            const dom = refs[refName]

            width.value = dom.clientWidth
            height.value = dom.clientHeight
        }

        onMounted(() => {
            init()
        })

        return {
            width,
            height,
            refName,
            path,
            pathId,
            radialId,
            maskId
        }
    }
}
</script>
<style lang="scss" scoped>
.nt-fly-box{
    position: relative;
    width: 100%;
    height: 100%;
    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .nt-fly-box-content {
        width: 100%;
        height: 100%;
        padding: 2px;
        box-sizing: border-box;
    }
}
</style>
