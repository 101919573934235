<template>
  <div class="home" >
    <schedule-view />
  </div>
</template>

<script>
  import ScheduleView from '@/components/ScheduleView/index'
  export default {
    name: 'TestScheduleView',
    components: { ScheduleView },
    setup () {
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
