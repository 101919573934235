<template>
  <div class="home" >
    <center-header :data="headerData" />
  </div>
</template>

<script>
  import CenterHeader from '@/components/CenterHeader/index'
  import useScreenData from '../hooks/useScreenData'
  export default {
    name: 'TestCenterHeader',
    components: { CenterHeader },
    setup () {
      return {
        ...useScreenData()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    /*width:100%;*/
    height:100%;
    width: 2980px;
    /*height: 206px;*/
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
