<template>
  <div class="nt-loading">
    <svg :width="width" :height="height" viewBox="0 0 50 50" preserveAspectRatio="xMidYMid meet">
      <!--
          stroke-dashArray => 2*pi*r/4 . 2*3.14*22/4=34
      -->
      <circle
        cx="25"
        cy="25"
        r="22"
        fill="none"
        stroke-width="3"
        :stroke= outSideColor
        stroke-dasharray="34"
        stroke-linecap="round"
      >
        <!--
            from="360 25 25"
            to="0 25 25"
            ==>
            values
        -->
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 25 25;360 25 25"
          :dur="`${duration}s`"
          repeatCount="indefinite"
        />
        <animate
          attributeName="stroke"
          :values="outsideColorAnimation"
          :dur="`${+duration * 2}s`"
          repeatCount="indefinite"
        />
      </circle>
      <circle
        cx="25"
        cy="25"
        r="12"
        fill="none"
        stroke-width="3"
        :stroke=inSideColor
        stroke-dasharray="19"
        stroke-linecap="round"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="360 25 25;0 25 25"
          :dur="`${duration}s`"
          repeatCount="indefinite"
        />

        <animate
          attributeName="stroke"
          :values="insideColorAnimation"
          :dur="`${+duration * 2}s`"
          repeatCount="indefinite"
        />

      </circle>
    </svg>
    <div class="nt-loading-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
  import { computed } from 'vue'
  export default {
    name: 'NtLoading',
    props: {
      width: {
        type: [Number, String],
        default: 50
      },
      height: {
        type: [Number, String],
        default: 50
      },
      outSideColor: {
        type: String,
        default: '#3be6cb'
      },
      inSideColor: {
        type: String,
        default: '02bcfe'
      },
      duration: {
        type: [Number, String],
        default: 2
      }
    },
    setup(ctx) {
      const outsideColorAnimation = computed(() =>
        `${ctx.outSideColor};${ctx.inSideColor};${ctx.outSideColor}`)

      const insideColorAnimation = computed(() =>
        `${ctx.inSideColor};${ctx.outSideColor};${ctx.inSideColor}`)

      return {
        outsideColorAnimation,
        insideColorAnimation
      }
    }
  }
</script>
<style lang="scss" scoped>
  .nt-loading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
</style>
