<template>
  <div class="nt-map" v-if="!loading">
    <nt-echarts :options="options"/>
  </div>
  <div class="nt-map" v-else>
    <div class="loading-wrapper">{{ loadingText }}</div>
  </div>
</template>

<script>
  import { ref, onMounted, onUnmounted, watch } from 'vue'
  import echarts from 'echarts'
  import NtEcharts from '@/components/NtEcharts/NtEcharts.vue'
  export default {
    name: 'YunnanMap3D',
    props: {
      mapData: Object,
      // centerMap: Array,
      loadFlag: {
        type: Boolean,
        default: true
      },
      centerMapValue: Object,
      centerMapDetail: Object
    },
    components: { NtEcharts },
    setup(props) {
      const options = ref({})
      const loadingText = ref('加载中.')
      const loading = ref(true)
      let loadingTimer = null
      const update = () => {
        if (props.loadFlag) {
          return
        }
        // console.log(props.mapData)
        // console.log(props.centerMap)
        echarts.registerMap('yunnan', props.mapData) // 注册地图
        options.value = {
          title: {
            text: '全省人口数据地图',
            subtext: '数据由百度数据提供',
            left: '2%',
            top: '2%',
            textStyle: {
              color: 'rgb(116,166,49)',
              fontSize: 35,
              fontWeight: 700
            }
          },
          grid: { // 不起作用
            // top: -10
            left: '100%'
          },
          visualMap: {
            show: true,
            max: 860,
            seriesIndex: [0],
            inRange: {
              // color: ['#a5dcf4', '#006edd']
              color: ['rgb(251,253,142)', 'rgb(116,166,49)']
            },
            // text: ['人口情况（万人）', 'Low']
            text: ['人口情况（万人）'],
            precision: 2,
            textStyle: {
              color: '#869f82',
              fontSize: 15
            }
          },
          series: [{
            type: 'map3D',
            map: 'yunnan',
            zoom: 0.3,
            boxWidth: 70,
            roam: false,
            label: {
              show: false,
              color: '#fff',
              // color: '#c6c6bc',
              emphasis: {
                color: '#fff',
                // color: '#d3c2ba',
                show: false
              }
            },
            itemStyle: {
              normal: {
                // borderColor: '#2980b9',
                borderColor: '#e3ddbd',
                borderWidth: 1,
                // areaColor: '#12235c'
                areaColor: '#1f640a'
              },
              emphasis: {
                // areaColor: '#fa8c16',
                areaColor: '#5ec9db',
                borderWidth: 0
              }
            },
            data: props.centerMapValue
          }]
        }
      }

      watch(() => props.loadFlag, () => {
        if (!props.loadFlag) {
          update()
          loading.value = false
          loadingTimer && clearInterval(loadingTimer)
        }
      })

      onMounted(() => {
        update()
        loadingTimer = setInterval(() => {
          if (loadingText.value === '加载中......') {
            loadingText.value = '加载中.'
          } else {
            loadingText.value += '.'
          }
        }, 200)
      })

      onUnmounted(() => {
        loadingTimer && clearInterval(loadingTimer)
      })

      return {
        options,
        loadingText,
        loading
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nt-map {
    width: 100%;
    height: 100%;

    .loading-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      color: #000;
    }
  }
</style>
