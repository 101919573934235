<template>
  <div class="total-user">
    <div class="text-wrapper">
      <div class="title-wrapper">
        <div class="title">云主机</div>
        <div class="sub-title">Cloud Host Total Count</div>
      </div>
      <div class="total">
        <count-to :startVal="startVal" :endVal="todayCloudHost" :duration="1000"/>
        <span class="dev-unit">台</span>
      </div>
    </div>
    <div class="percent-wrapper">
      <div class="percent-text">
        <span class="percent-text-1">
          每日增长率:
          <count-to
            :start-val="startPercent"
            :end-val="growthCloudLastDay"
            :duration="1000"
            :decimals="2"
            suffix="%"
          />
        </span>
        <span class="percent-text-2">
        每月增长率:
        <count-to
          :start-val="startPercent2"
          :end-val="growthCloudLastMonth"
          :duration="1000"
          :decimals="2"
          suffix="%"
        />
        </span>
      </div>
      <div class="item-wrapper">
        <div class="cloud-item">CPU 配额总量
          <count-to
            :start-val="startPercent2"
            :end-val="totalCloudCPU"
            :duration="1000"
            :decimals="0"
          />
          核 剩余量
          <count-to
            :start-val="startPercent2"
            :end-val="remainCloudCPU"
            :duration="1000"
            :decimals="0"
          />
          核 </div>
        <div class="percent">
          <div class="percent-inner-wrapper">
            <div class="percent-inner" :style="{ width: `${remainCloudCPU/totalCloudCPU*100}%` }"/>
          </div>
        </div>
      </div>
      <div class="item-wrapper">
        <div class="cloud-item">内存 配额总量
          <count-to
            :start-val="startPercent2"
            :end-val="totalCloudMemory"
            :duration="1000"
            :decimals="0"
          />
          GB 剩余量
          <count-to
            :start-val="startPercent2"
            :end-val="remainCloudMemory"
            :duration="1000"
            :decimals="0"
          />
          GB</div>
        <div class="percent">
          <div class="percent-inner-wrapper">
            <div class="percent-inner" :style="{ width: `${remainCloudMemory/totalCloudMemory*100}%` }"/>
          </div>
        </div>
      </div>
      <div class="item-wrapper">
        <div class="cloud-item">存储 配额总量
          <count-to
            :start-val="startPercent2"
            :end-val="totalCloudDisk"
            :duration="1000"
            :decimals="0"
          />
          GB 剩余量
          <count-to
            :start-val="startPercent2"
            :end-val="remainCloudDisk"
            :duration="1000"
            :decimals="0"
          />
          GB</div>
        <div class="percent">
          <div class="percent-inner-wrapper">
            <div class="percent-inner" :style="{ width: `${remainCloudDisk/totalCloudDisk*100}%` }"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  import countTo from '../VueCountTo/vue-countTo'

  export default {
    name: 'totalCloudHost',
    props: {
      todayCloudHost: {
        type: Number,
        default: 0
      },
      growthCloudLastDay: {
        type: Number,
        default: 0
      },
      growthCloudLastMonth: {
        type: Number,
        default: 0
      },
      totalCloudCPU: {
        type: Number,
        default: 0
      },
      remainCloudCPU: {
        type: Number,
        default: 0
      },
      totalCloudMemory: {
        type: Number,
        default: 0
      },
      remainCloudMemory: {
        type: Number,
        default: 0
      },
      totalCloudDisk: {
        type: Number,
        default: 0
      },
      remainCloudDisk: {
        type: Number,
        default: 0
      }
    },
    components: { countTo },
    setup(props) {
      const startVal = ref(0)
      const startPercent = ref(0)
      const startPercent2 = ref(0)
      watch(() => props.todayCloudHost, (nextValue, prevValue) => {
        startVal.value = prevValue
      })
      watch(() => props.growthCloudLastDay, (nextValue, prevValue) => {
        startPercent.value = prevValue
      })
      watch(() => props.growthCloudLastMonth, (nextValue, prevValue) => {
        startPercent2.value = prevValue
      })
      return {
        startVal,
        startPercent,
        startPercent2
      }
    }
  }
</script>

<style lang="scss" scoped>
  .total-user {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /*background: rgb(66, 68, 70);*/
    /*background: rgb(43, 44, 46);*/
    box-shadow: 0 10px 10px rgba(0, 0, 0, .3);
    padding: 20px 40px;
    box-sizing: border-box;

    .text-wrapper {
      display: flex;

      .title-wrapper {
        .title {
          font-size: 32px;
        }

        .sub-title {
          font-size: 16px;
          letter-spacing: 1px;
          margin-top: 10px;
        }
      }

      .total {
        margin-left: 40px;
        font-family: DIN;
        font-size: 68px;
        font-weight: bolder;
        letter-spacing: 2px;
        /*padding: 10px 0;*/

        .dev-unit {
          font-size: 20px;
        }
      }
    }

    .percent-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .percent-text {
        font-size: 28px;
        font-family: DIN;
        letter-spacing: 2px;
        padding-bottom: 10px;

        .percent-text-1 {
          /*color: rgb(197, 251, 121);*/
          color: #5ec9db
        }

        .percent-text-2 {
          /*color: rgb(99, 169, 0);*/
          color: #6462cc;
          margin-left: 10px;
        }
      }
      .item-wrapper{
        display: flex;
        align-items: center;

        .cloud-item{
          width: 100%;
          font-size: 20px;
          /*margin-left: 40px;*/
        }
        .percent {
          width: 100%;
          height: 20px;
          box-sizing: border-box;
          border: 1px solid #fff;
          margin-top: 10px;
          margin-left: 40px;

          .percent-inner-wrapper {
            height: 100%;
            padding: 2px;
            box-sizing: border-box;
            overflow: hidden;

            .percent-inner {
              height: 100%;
              background: rgb(150, 150, 150);
              transition: width 1s linear;
            }
          }
        }
      }
    }
  }
</style>
