<template>
  <div class="home" >
    <total-project :data="projectData"  />
  </div>
</template>

<script>
  import TotalProject from '@/components/TotalProject/index'
  import { onMounted, onUnmounted, ref } from 'vue'
  /* eslint-disable */
  const projectMockData = {
    "totalProjects":1070909,
    "projects":[{"key":"在谈","value":423676},
      {"key":"落地","value":373581},
      {"key":"投产","value":273652}]}
  /* eslint-enable */

  export default {
    name: 'TestTotalProject',
    components: { TotalProject },
    setup () {
      const projectData = ref(projectMockData)
      let timer
      const update = () => {
        const _projectData = { ...projectData.value }
        _projectData.projects.forEach(item => {
          item.value += 1
        })
        _projectData.totalProjects += 3
        projectData.value = _projectData
      }
      onMounted(() => {
        timer = setInterval(() => {
          update()
        }, 3000)
      })

      onUnmounted(() => {
        timer && clearInterval(timer)
      })
      return {
        projectData
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
