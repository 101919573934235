<template>
  <div class="hot-category">
    <div class="hot-category-title-wrapper">
      <div class="hot-category-left">
        <div class="title">当前热卖服务</div>
        <div class="sub-title">Hot Service Categories</div>
      </div>
      <div class="hot-category-right">
        <div class="sub-title">最后更新时间：{{date}} {{time}}</div>
      </div>
    </div>
    <div id="hot-category-chart" >
      <nt-echarts :options="options" />
    </div>
  </div>
</template>

<script>
import { watch, onMounted, ref } from 'vue'
import { clock as useClock } from '../../hooks/useClock'
import NtEcharts from '../NtEcharts/NtEcharts'

export default {
  name: 'HotCategory',
  props: {
    data: Object
  },
  components: { NtEcharts },
  setup (props) {
    const { time, date } = useClock()
    let currentChart = 0
    const options = ref(null)
    const update = () => {
      if (!props.data) {
        return
      }
      const sourceData = []
      const { data1, data2 } = props.data
      // console.log('data', props.data)
      if (currentChart === 0) {
        data1.axisX.forEach((axis, index) => {
          sourceData.push([axis, data1.data1[index], data1.data2[index]])
        })
      } else {
        data2.axisX.forEach((axis, index) => {
          sourceData.push([axis, data2.data1[index], data2.data2[index]])
        })
      }
      const source = [
        ['指标', '服务', '产品'],
        ...sourceData
      ]
      // console.log(source)
      options.value = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          show: true,
          top: '-2%',
          right: '5%',
          textStyle: {
            color: '#ddd'
          }
        },
        color: ['#5ec9db', '#6462cc'],
        // color: ['rgb(209,248,138)', 'rgb(190,245,99)'],
        grid: {
          left: 40,
          right: 0,
          bottom: 30,
          top: 20
        },
        dataset: {
          source
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              type: 'dotted'
            }
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'rgb(124,136,146)'
            }
          },
          axisLabel: {
            color: 'rgb(98,105,113)',
            fontSize: 16
          },
          axisTick: { show: false }
        },
        xAxis: {
          type: 'category',
          splitLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            color: 'rgb(98,105,113)',
            fontSize: 16
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              // color: 'red'
              color: 'rgb(124,136,146)'
            }
          }
        },
        series: [
          {
            type: 'bar',
            stack: 'total',
            barWidth: 40
          },
          {
            type: 'bar',
            stack: 'total'
          }
        ]
      }
      currentChart === 0 ? currentChart = 1 : currentChart = 0
    }
    watch(() => props.data, () => {
      update()
    })
    onMounted(() => {
      update()
    })
    return {
      time,
      date,
      options
    }
  }
}
</script>

<style lang="scss" scoped>
  .hot-category {
    width: 100%;
    height: 100%;
    /*background: rgb(43, 44, 46);*/
    padding: 20px 40px;
    box-sizing: border-box;

    .hot-category-title-wrapper {
      display: flex;

      .hot-category-right {
        flex: 1;
        text-align: right;
      }

      .title {
        font-size: 32px;
      }

      .sub-title {
        font-size: 16px;
        margin-top: 10px;
      }
    }

    #hot-category-chart {
      height: 220px;
    }
  }
</style>
