<template>
  <div class="total-user">
    <div class="text-wrapper">
      <div class="title-wrapper">
        <div class="title">宿主机</div>
        <div class="sub-title">Host Total Count</div>
      </div>
      <div class="total">
        <count-to :startVal="startVal" :endVal="todayHost" :duration="1000"/>
        <span class="dev-unit">台</span>
      </div>
    </div>
    <div class="percent-wrapper">
<!--      <div class="percent-text">-->
<!--        <span class="percent-text-1">-->
<!--          每日增长率:-->
<!--          <count-to-->
<!--            :start-val="startPercent"-->
<!--            :end-val="growthLastDay"-->
<!--            :duration="1000"-->
<!--            :decimals="2"-->
<!--            suffix="%"-->
<!--          />-->
<!--        </span>-->
<!--        <span class="percent-text-2">-->
<!--        每月增长率:-->
<!--        <count-to-->
<!--          :start-val="startPercent2"-->
<!--          :end-val="growthLastMonth"-->
<!--          :duration="1000"-->
<!--          :decimals="2"-->
<!--          suffix="%"-->
<!--        />-->
<!--        </span>-->
<!--      </div>-->
      <div class="item-wrapper">
        <div class="cloud-item">CPU 配额总量
          <count-to
            :start-val="startPercent2"
            :end-val="totalCPU"
            :duration="1000"
            :decimals="0"
          />
          核 剩余量
          <count-to
            :start-val="startPercent2"
            :end-val="remainCPU"
            :duration="1000"
            :decimals="0"
          />
          核 </div>
        <div class="percent">
          <div class="percent-inner-wrapper">
            <div class="percent-inner" :style="{ width: `${remainCPU/totalCPU*100}%` }"/>
          </div>
        </div>
      </div>
      <div class="item-wrapper">
        <div class="cloud-item">内存 配额总量
          <count-to
            :start-val="startPercent2"
            :end-val="totalMemory"
            :duration="1000"
            :decimals="0"
          />
          GB 剩余量
          <count-to
            :start-val="startPercent2"
            :end-val="remainMemory"
            :duration="1000"
            :decimals="0"
          />
          GB</div>
        <div class="percent">
          <div class="percent-inner-wrapper">
            <div class="percent-inner" :style="{ width: `${remainMemory/totalMemory*100}%` }"/>
          </div>
        </div>
      </div>
      <div class="item-wrapper">
        <div class="cloud-item">硬盘 配额总量
          <count-to
            :start-val="startPercent2"
            :end-val="totalDisk"
            :duration="1000"
            :decimals="0"
          />
          GB 剩余量
          <count-to
            :start-val="startPercent2"
            :end-val="remainDisk"
            :duration="1000"
            :decimals="0"
          />
          GB</div>
        <div class="percent">
          <div class="percent-inner-wrapper">
            <div class="percent-inner" :style="{ width: `${remainDisk/totalDisk*100}%` }"/>
          </div>
        </div>
      </div>
      <div class="item-wrapper">
        <div class="cloud-item">负载均衡比:
          <span class="cloud-balance">{{ balaceRate }}%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  import countTo from '../VueCountTo/vue-countTo'
  function toFixed (num, decimals) {
    return num.toFixed(decimals)
  }
  export default {
    name: 'totalHost',
    props: {
      todayHost: {
        type: Number,
        default: 0
      },
      growthLastDay: {
        type: Number,
        default: 0
      },
      growthLastMonth: {
        type: Number,
        default: 0
      },
      totalCPU: {
        type: Number,
        default: 0
      },
      remainCPU: {
        type: Number,
        default: 0
      },
      totalMemory: {
        type: Number,
        default: 0
      },
      remainMemory: {
        type: Number,
        default: 0
      },
      totalDisk: {
        type: Number,
        default: 0
      },
      remainDisk: {
        type: Number,
        default: 0
      },
      balanceMax: {
        type: Number,
        default: 0
      },
      balanceMin: {
        type: Number,
        default: 0
      }
    },
    components: { countTo },
    setup(props) {
      const startVal = ref(0)
      const startPercent = ref(0)
      const startPercent2 = ref(0)
      const balaceRate = ref(toFixed(props.balanceMax / props.balanceMin * 100, 2))
      watch(() => props.todayUser, (nextValue, prevValue) => {
        startVal.value = prevValue
      })
      watch(() => props.growthLastDay, (nextValue, prevValue) => {
        startPercent.value = prevValue
      })
      watch(() => props.growthLastMonth, (nextValue, prevValue) => {
        startPercent2.value = prevValue
        balaceRate.value = toFixed(props.balanceMax / props.balanceMin * 100, 2)
      })
      return {
        startVal,
        startPercent,
        startPercent2,
        balaceRate
      }
    }
  }
</script>

<style lang="scss" scoped>
  .total-user {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /*background: rgb(66, 68, 70);*/
    /*background: rgb(43, 44, 46);*/
    box-shadow: 0 10px 10px rgba(0, 0, 0, .3);
    padding: 20px 40px;
    box-sizing: border-box;

    .text-wrapper {
      display: flex;

      .title-wrapper {
        .title {
          font-size: 32px;
        }

        .sub-title {
          font-size: 16px;
          letter-spacing: 1px;
          margin-top: 10px;
        }
      }

      .total {
        margin-left: 40px;
        font-family: DIN;
        font-size: 68px;
        font-weight: bolder;
        letter-spacing: 2px;
        /*padding: 10px 0;*/

        .dev-unit {
          font-size: 20px;
        }
      }
    }

    .percent-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .percent-text {
        font-size: 28px;
        font-family: DIN;
        letter-spacing: 2px;
        padding-bottom: 10px;

        .percent-text-1 {
          color: rgb(197, 251, 121);
        }

        .percent-text-2 {
          color: rgb(99, 169, 0);
          margin-left: 10px;
        }
      }
      .item-wrapper{
        display: flex;
        align-items: center;

        .cloud-item{
          width: 100%;
          font-size: 20px;
          /*margin-left: 40px;*/
          .cloud-balance{
            color: #5ec9db;
            font-size: 24px;
          }
        }
        .percent {
          width: 100%;
          height: 20px;
          box-sizing: border-box;
          border: 1px solid #fff;
          margin-top: 10px;
          margin-left: 40px;

          .percent-inner-wrapper {
            height: 100%;
            padding: 2px;
            box-sizing: border-box;
            overflow: hidden;

            .percent-inner {
              height: 100%;
              background: rgb(150, 150, 150);
              transition: width 1s linear;
            }
          }
        }
      }
    }
  }
</style>
