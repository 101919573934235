<template>
  <div class="home" >
    <average-age :data="ageData" :avg-age="averageAge" />
  </div>
</template>

<script>
  import AverageAge from '@/components/AverageAge/index'
  import { ref, onMounted, onUnmounted } from 'vue'
  /* eslint-disable */
  const ageMockData = [
    {"startValue":0,"value":131107,"axis":"0-20","color":"rgb(116,166,49)"},
    {"startValue":0,"value":330831,"axis":"20-30","color":"rgb(190,245,99)"},
    {"startValue":0,"value":551238,"axis":"30-50","color":"rgb(202,252,137)"},
    {"startValue":0,"value":31088,"axis":">50","color":"rgb(251,253,142)"}]
  /* eslint-enable */

  function random (val) {
    return Math.floor(Math.random() * val)
  }
  export default {
    name: 'TestAverageAge',
    components: { AverageAge },
    setup () {
      const ageData = ref(ageMockData)
      const averageAge = ref(50)

      let timer

      const update = () => {
        averageAge.value = averageAge.value + 1
        const _ageData = [...ageData.value]
        // console.log(_ageData)

        _ageData.forEach(item => {
          item.startValue = item.value
          const r = random(100)
          item.value = item.value + r
        })
        ageData.value = _ageData
      }

      onMounted(() => {
        timer = setInterval(() => {
          update()
        }, 3000)
      })

      onUnmounted(() => {
        timer && clearInterval(timer)
      })

      return {
        ageData,
        averageAge
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
