<template>
  <div class="home" >
    <nt-fly-box startColor="rgb(253,251,142)" duration="5">
      <real-time-order :data="realTimeOrderData"/>
    </nt-fly-box>
  </div>
</template>

<script>
  import RealTimeOrder from '@/components/RealTimeOrder/index'
  import NtFlyBox from '@/components/NtFlyBox/NtFlyBox'
  import { ref, onMounted, onUnmounted } from 'vue'
  /* eslint-disable */
  const realTimeOrderMockData = {
    "date":["12:25:13","12:25:17","12:25:22","12:25:27","12:25:32","12:25:37","12:25:42","12:25:47","12:25:52","12:25:57"],
    "data":[1143,769,251,733,335,969,869,1390,168,1391],
    "percent": 30,
    "total": 1001
  }
  /* eslint-enable */
  export default {
    name: 'TestRealTimeOrder',
    components: { RealTimeOrder, NtFlyBox },
    setup () {
      const realTimeOrderData = ref(realTimeOrderMockData)
      let timer

      const update = () => {
        const _realTimeOrderData = { ...realTimeOrderData.value }
        _realTimeOrderData.data = _realTimeOrderData.data.map(item => {
          item += 20
          return item
        })
        _realTimeOrderData.total += 123
        _realTimeOrderData.percent += 1
        realTimeOrderData.value = _realTimeOrderData
      }

      onMounted(() => {
        timer = setInterval(() => {
          update()
        }, 3000)
      })

      onUnmounted(() => {
        timer && clearInterval(timer)
      })

      return {
        realTimeOrderData
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
