<template>
  <div class="home" >
    <test-map :mapData="mapData"  :loadFlag="loadFlag"/>
<!--    <transform-category :data="['ALL','北京','上海','广州','昆明']"-->
<!--                        :color="['rgb(178,209,126)', 'rgb(116,166,49)']"/>-->
  </div>
</template>

<script>
  import TestMap from '@/components/KunmingMap/indexMap'
  // import TransformCategory from '@/components/TransformCategory/index'
  import { ref, onMounted } from 'vue'
  export default {
    name: 'TestMapTest',
    components: { TestMap },
    setup () {
      const mapData = ref({})
      const loadFlag = ref(true)
      onMounted(() => {
        // fetch('./data/map.json')
        fetch('./data/100000_full.json')
          .then(response => response.json())
          .then(data => {
            mapData.value = data
            loadFlag.value = false
          })
      })
      return {
        mapData,
        loadFlag
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
