<template>
  <div class="home" >
    <kunming-map
      :mapData="mapData"
      :centerMapValue="centerMapValue"
      :fightMapDetail="fightMapDetail"
      :loadFlag="loading"/>
  </div>
</template>

<script>
  import KunmingMap from '@/components/KunmingMap3D/index'
  import useKunmingMap from '../hooks/useKmMap'
  export default {
    name: 'TestMapKm3d',
    components: { KunmingMap },
    setup () {
      return {
        ...useKunmingMap()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
