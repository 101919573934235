<template>
  <div class="total-host">
    <div class="total-host-left">
      <div id="total-host-chart" >
        <nt-echarts :options="options"></nt-echarts>
      </div>
    </div>
    <div class="total-host-right">
      <div class="title-wrapper">
        <div class="total-host-right-left">
          <div class="title">云主机规格概况</div>
          <div class="sub-title">Distribution of Cloud Host</div>
        </div>
<!--        <div class="total-project-right-right">-->
<!--          <div class="project">-->
<!--            <count-to-->
<!--              :startVal="startNum"-->
<!--              :endVal="num"-->
<!--              :duration="1000"-->
<!--            />-->
<!--            <span class="project-unit">个</span>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div class="host-data-wrapper">
        <div class="host-data" v-for="(item, index) in refData" :key="index">
          <div class="host-data-value">
            <count-to
              :startVal="item.startValue"
              :endVal="item.value"
              :duration="1000"
            />
          </div>
          <div class="host-data-axis">
            <div class="point" :style="{background: item.color}" />
            <div class="text">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
import countTo from '../VueCountTo/vue-countTo'
import NtEcharts from '../NtEcharts/NtEcharts'

// const color = ['rgb(176,207,120)', 'rgb(157,195,91)', 'rgb(131,167,72)', 'rgb(116,166,49)']
const color = ['#5ec9db', '#fdc765', '#f27d51', '#6462cc']

export default {
  name: 'TotalHostCategory',
  props: {
    data: Object
  },
  components: { countTo, NtEcharts },
  setup (props) {
    // let chart
    const refData = ref([])
    const num = ref(0)
    const startNum = ref(0)
    const options = ref(null)
    // console.log('testNumber', props.testNumber)
    const updateChart = () => {
      options.value = {
        series: [{
          name: '项目总数',
          type: 'pie',
          data: refData.value,
          // roseType: 'radius',
          // radius: '70%',
          center: ['55%', '50%'],
          radius: ['45%', '60%'],
          label: { show: false },
          emphasis: {
            itemStyle: {
              color: 'rgb(140,251,182)'
            }
          },
          color,
          selectedMode: 'multiple',
          selectedOffset: 10,
          // center: ['100%', '10%'],
          clockwise: true
        }]
      }
    }
    const update = (newData) => {
      // console.log('update', refData)
      // console.log(newData)
      if (!newData || !newData.hosts) {
        return
      }
      if (refData.value.length > 0) {
        refData.value = newData.hosts.map((item, index) => ({
          startValue: refData.value[index].value,
          value: item.value,
          name: item.key,
          color: color[index]
        }))
      } else {
        refData.value = newData.hosts.map((item, index) => ({
          startValue: 0,
          value: item.value,
          name: item.key,
          color: color[index]
        }))
      }
      startNum.value = num.value
      num.value = +newData.totalHost
      // console.log(refData)
      updateChart()
    }
    watch(() => props.data, (nextValue, prevValue) => {
      // console.log('watch', nextValue)
      update(nextValue)
    })
    onMounted(() => {
      update(props.data)
    })
    return {
      refData,
      num,
      startNum,
      options
    }
  }
}
</script>

<style lang="scss" scoped>
  .total-host {
    display: flex;
    width: 100%;
    height: 100%;
    /*background: rgb(43, 44, 46);*/

    .total-host-left {
      width: 30%;
      height: 100%;

      #total-host-chart {
        width: 100%;
        height: 100%;
      }
    }

    .total-host-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 40px 10px 40px 0;

      .title-wrapper {
        display: flex;
        align-items: center;

        .total-host-right-left {
          .title {
            font-size: 32px;
          }

          .sub-title {
            font-size: 16px;
            margin-top: 10px;
          }
        }

        .total-host-right-right {
          flex: 1;
          margin-left: 40px;
          font-weight: bold;

          .host {
            font-size: 56px;
            font-family: DIN;

            .host-unit {
              font-size: 20px;
            }
          }
        }
      }

      .host-data-wrapper {
        display: flex;

        .host-data {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          font-weight: bolder;

          .host-data-value {
            width: 100%;
          }

          .host-data-axis {
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 5px;

            .point {
              width: 10px;
              height: 10px;
              border-radius: 50%;
            }

            .text {
              margin-left: 10px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
</style>
