import { ref, onMounted, onUnmounted } from 'vue'

/* eslint-disable */
const hostMockData = {
  "totalHost":19,
  "hosts":[{"key":"16核 32GB","value":8},
    {"key":"2核 4GB","value":3},
    {"key":"16核 128GB","value":2},
    {"key":"其他","value":6}
  ]}
/* eslint-enable */

/* eslint-disable */
const hostMonitorMockData = [
  {"startValue":0,"value":19,"axis":"正常 ACTIVE","color":"#5ec9db"},
  {"startValue":0,"value":0,"axis":"停止 SHUTOFF","color":"#fdc765"},
  {"startValue":0,"value":0,"axis":"异常 ERROR","color":"#f27d51"},
  {"startValue":0,"value":0,"axis":"被删除 DELETED","color":"#6462cc"}]
/* eslint-enable */

/* eslint-disable */
const hostStatusMockData = {
  "axisX":["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月"],
  "cpuData":{
    "legend1":"去年CPU运行负载","legend2":"今年CPU运行负载",
    "data1":["33","42","56","45","61","89","72","61","58","75","77","60"],
    // "data2":["43","51","66","55","71","99","62","55","76","81","93","72"]},
    "data2":["43","51","66"]},
  "memData":{
    "legend1":"去年内存运行负载","legend2":"今年内存运行负载",
    "data1":["12","22","20","19","30","11","33","24","17","32","40","16"],
    // "data2":["17","26","28","29","33","34","22","29","19","45","56","23"]}}
    "data2":["17","26","28"]},
  "diskData":{
    "legend1":"去年硬盘运行负载","legend2":"今年硬盘运行负载",
    "data1":["2","12","10","9","20","1","3","14","7","22","30","6"],
    // "data2":["17","26","28","29","33","34","22","29","19","45","56","23"]}}
    "data2":["7","16","18"]}
  }
/* eslint-enable */

/* eslint-disable */
const hostSaleMockData = {
  "data1":{
    "axisX":["云主机","云存储","弹性IP","CDN","运维安全管理","安全态势感知","防火墙WAF"],
    "data1":[50,29,46,52,79,69,67],
    "data2":[50,71,54,48,21,31,33]},
  "data2":{"axisX":["CPU","内存","存储","IP","网络安全","存储安全","防火墙"],
    "data1":[35,40,33,26,63,31,19],
    "data2":[65,60,67,74,37,69,81]}
}
/* eslint-enable */

/* eslint-disable */
const headerMockData = {
  "headerData":[
    {"title":"资源池计算","subTitle":"Resource pool CPU","startVal":606,"endVal":606},
    {"title":"资源池内存","subTitle":"Resource pool Memory","startVal":1212,"endVal":1212},
    {"title":"资源池存储","subTitle":"Resource pool Disk","startVal":43008,"endVal":43008},
    {"title":"带宽","subTitle":"Bandwidth","startVal":100,"endVal":100}],
  "project":[
    {"title":"RDS","value":"1","img":"./assets/success.png"},
    {"title":"SSL VPN","value":"1","img":"./assets/failed.png"}]}
/* eslint-enable */
const listMockSum = ['5', '8', '10', '20', '30', '40', '50', '80']
const listMockTenant = ['云南按钮科技技术有限公司', '云平台数字展厅', '云平台数字展厅', '产权', '南天软件部门', '双录', '南天金融软件开发部', '云南南天']

/* eslint-disable */
const tenantCostMockData = {
  "totalCost":1070909,
  "projects":[{"key":"包年包月一年","value":22367},
    {"key":"包年包月二年","value":37358},
    {"key":"包年包月三年","value":13652},
    {"key":"按量计费","value":23652}
    ]}
/* eslint-enable */

/* eslint-disable */
const workListMockData = [
  {"order":"计费相关","shop":"2023年1月17日","rider":"**软件部门","newShop":"2023年1月17日","avgOrder":"已关闭"},
  {"order":"云主机故障","shop":"2023年1月17日","rider":"**软件部门","newShop":"2023年1月17日","avgOrder":"已关闭"},
  {"order":"账单咨询","shop":"2023年1月17日","rider":"**软件部门","newShop":"2023年1月17日","avgOrder":"已关闭"},
  {"order":"用户账号问题","shop":"2023年1月17日","rider":"**软件部门","newShop":"2023年1月17日","avgOrder":"已关闭"},
  {"order":"备案问题","shop":"2023年1月17日","rider":"**技术有限公司","newShop":"2023年1月17日","avgOrder":"已关闭"},
  {"order":"配额申请","shop":"2023年1月17日","rider":"**技术有限公司","newShop":"-","avgOrder":"正在处理"},
  {"order":"远程登录","shop":"2023年1月17日","rider":"**技术有限公司","newShop":"-","avgOrder":"正在处理"},
  {"order":"资源售罄","shop":"2023年1月17日","rider":"**技术有限公司","newShop":"-","avgOrder":"正在处理"},
  {"order":"数据备份","shop":"2023年1月17日","rider":"**技术有限公司","newShop":"-","avgOrder":"正在处理"},
  {"order":"数据恢复","shop":"2023年1月17日","rider":"**技术有限公司","newShop":"-","avgOrder":"正在处理"},
  {"order":"云主机创建","shop":"2023年1月17日","rider":"**技术有限公司","newShop":"-","avgOrder":"正在处理"},
  {"order":"无法访问外网","shop":"2023年1月17日","rider":"**技术有限公司","newShop":"-","avgOrder":"正在处理"},
  ]
const securityListMockData = [
  {"order":"DDOS防护","shop":"00:00:00","rider":"**软件部门","newShop":"2023年1月17日","avgOrder":"已关闭"},
  {"order":"WAF","shop":"00:00:00","rider":"**软件部门","newShop":"2023年1月17日","avgOrder":"已关闭"},
  {"order":"云防火墙","shop":"00:00:00","rider":"**软件部门","newShop":"2023年1月17日","avgOrder":"已关闭"},
  {"order":"漏扫","shop":"00:00:00","rider":"**软件部门","newShop":"2023年1月17日","avgOrder":"已关闭"},
  {"order":"主机安全","shop":"00:00:00","rider":"**技术有限公司","newShop":"2023年1月17日","avgOrder":"已关闭"},
  {"order":"DDOS防护","shop":"03:00:00","rider":"**技术有限公司","newShop":"-","avgOrder":"正在处理"},
  {"order":"WAF","shop":"03:00:00","rider":"**技术有限公司","newShop":"-","avgOrder":"正在处理"},
  {"order":"云防火墙","shop":"03:00:00","rider":"**技术有限公司","newShop":"-","avgOrder":"正在处理"},
  {"order":"漏扫","shop":"03:00:00","rider":"**技术有限公司","newShop":"-","avgOrder":"正在处理"},
  {"order":"主机安全","shop":"03:00:00","rider":"**技术有限公司","newShop":"-","avgOrder":"正在处理"},
  {"order":"DDOS防护","shop":"09:00:00","rider":"**技术有限公司","newShop":"-","avgOrder":"正在处理"},
  {"order":"WAF","shop":"09:00:00","rider":"**技术有限公司","newShop":"-","avgOrder":"正在处理"},
]

const resourceListMockData = [
  {"order":"资源池计算CPU","shop":"606核","rider":"501","newShop":"105","avgOrder":"0.83"},
  {"order":"资源池计算内存","shop":"1212DB","rider":"1205","newShop":"7","avgOrder":"0.99"},
  {"order":"高性能存储","shop":"43008GB","rider":"22278","newShop":"20730","avgOrder":"0.52"},
  {"order":"云主机CPU","shop":"554核","rider":"449","newShop":"105","avgOrder":"0.81"},
  {"order":"云主机内存","shop":"1108GB","rider":"1101","newShop":"7","avgOrder":"0.99"},
  {"order":"私有镜像空间","shop":"50GB","rider":"50","newShop":"0","avgOrder":"1.00"},
  {"order":"宽带","shop":"108Mbps","rider":"108","newShop":"0","avgOrder":"1.00"},
  {"order":"弹性IP","shop":"18个","rider":"16","newShop":"2","avgOrder":"0.89"},
  {"order":"应用支持2核4G，最大吞吐量500Mbps","shop":"1","rider":"1","newShop":"0","avgOrder":"1.00"},
  {"order":"对象存储容量","shop":"1024GB","rider":"1000","newShop":"24","avgOrder":"0.98"},
  {"order":"备份空间","shop":"87040GB","rider":"4500","newShop":"82540","avgOrder":"0.05"},
  {"order":"Oracle运维管理服务纳管实例（规格不限）","shop":"2台","rider":"2","newShop":"0","avgOrder":"1.00"},
]
/* eslint-enable */

const radarMockData = {
  indicator: [
    { name: '云主机', max: 50 },
    { name: '宿主机', max: 50 }, // 标签设置为红色
    { name: '存储', max: 50 },
    { name: '网络', max: 50 },
    { name: '防火墙', max: 50 },
    { name: '其他', max: 50 }],
  data: [{
    value: [5, 5, 2, 3, 5, 2],
    name: '紧急告警',
    itemStyle: {
      color: 'red'
    }
  }, {
    value: [8, 3, 5, 3, 5, 2],
    name: '重要告警',
    itemStyle: {
      color: 'orange'
    }
  }, {
    value: [14, 3, 25, 32, 31, 20],
    name: '轻微告警',
    itemStyle: {
      color: 'blue'
    }
  }]
}
const cpuAxisMockData = ['02/26', '02/27', '02/28',
  '03/01', '03/02', '03/03', '03/04', '03/05', '03/06', '03/07', '03/08', '03/09', '03/10',
  '03/11', '03/12', '03/13', '03/14', '03/15', '03/16', '03/17', '03/18', '03/19', '03/20',
  '03/21', '03/22', '03/23', '03/24', '03/25', '03/26', '03/27', '03/28', '03/29', '03/30'
]
const cpuMockUsage = [
  3, 6, 5,
  10, 3, 2, 2, 1, 5, 4, 3, 2, 2,
  7, 2, 2, 2, 2, 3, 2, 3, 2, 3,
  7, 2, 2, 2, 2, 3, 2, 3, 2, 3
]
const memMockUsage = [
  3, 3, 4,
  6, 3, 2, 2, 4, 5, 4, 3, 2, 2,
  4, 2, 2, 2, 2, 3, 2, 5, 2, 3,
  4, 2, 2, 5, 2, 3, 2, 5, 2, 2
]
const diskMockUsage = [
  3, 6, 5,
  3, 3, 2, 2, 1, 5, 1, 3, 2, 2,
  3, 2, 2, 2, 2, 3, 2, 3, 2, 3,
  3, 2, 2, 2, 2, 3, 2, 3, 2, 1
]

const resourceCategoryTop10 = [
  { name: 'broadband', value: 100 },
  { name: 'Private Mirror Space', value: 100 },
  { name: 'Next Generation FireWall', value: 100 },
  { name: 'Resource Pool Computing Memory', value: 99 },
  { name: 'Virtual machine memory', value: 99 },
  { name: 'Object storage capacity', value: 98 },
  { name: 'Resource Pool Computing CPU', value: 83 },
  { name: 'Cloud host CPU', value: 81 },
  { name: 'High performance storage', value: 51 },
  { name: 'Backup space', value: 5 }
] // 类别

function random (val) {
  return Math.floor(Math.random() * val)
}

export default function () {
  const todayCloudHost = ref(19)
  const growthCloudLastDay = ref(0)
  const growthCloudLastMonth = ref(10)
  const totalCloudCPU = ref(600)
  const remainCloudCPU = ref(349)
  const totalCloudMemory = ref(1200)
  const remainCloudMemory = ref(411)
  const totalCloudDisk = ref(7528)
  const remainCloudDisk = ref(1000)
  const hostData = ref(hostMockData)
  const hostMonitorData = ref(hostMonitorMockData)

  const todayHost = ref(3)
  const growthLastDay = ref(0)
  const growthLastMonth = ref(0)
  const totalCPU = ref(606)
  const remainCPU = ref(381)
  const totalMemory = ref(1212)
  const remainMemory = ref(475)
  const totalDisk = ref(30000)
  const remainDisk = ref(15000)
  const balanceMax = ref(30)
  const balanceMin = ref(10)

  const hostStatusData = ref(hostStatusMockData)

  const hostSaleData = ref(hostSaleMockData)

  const headerData = ref(headerMockData)

  const listSum = ref(listMockSum)
  const listTenant = ref(listMockTenant)
  const tenantCostData = ref(tenantCostMockData)
  const tenantNum = ref(8)
  const tenantGroups = ref(3)
  const growthTenantLastDay = ref(8)
  const growthTenantLastMonth = ref(1)

  const workListData = ref(workListMockData)

  const radarData = ref(radarMockData)
  const securityListData = ref(securityListMockData)

  const videoUrl = ref('/assets/eduCloud.mov')
  const poster = ref('/assets/yn.png')
  const cpuRate = ref(12)
  const cpuAxisData = ref(cpuAxisMockData)
  const cpuUsage = ref(cpuMockUsage)
  const memUsage = ref(memMockUsage)
  const diskUsage = ref(diskMockUsage)
  const resourceTop10 = ref(resourceCategoryTop10)
  const resourceListData = ref(resourceListMockData)

  let timer
  let flag1 = true
  let times = 0

  const update = () => {
    todayCloudHost.value += 1
    growthCloudLastDay.value += 2
    growthCloudLastMonth.value += 5
    totalCloudCPU.value += 1
    remainCloudCPU.value += 1
    totalCloudMemory.value += 1
    remainCloudMemory.value += 1
    totalCloudDisk.value += 1
    remainCloudDisk.value += 1

    const _hostData = { ...hostData.value }
    _hostData.hosts.forEach(item => {
      item.value += 1
    })
    _hostData.totalHost += 3
    hostData.value = _hostData

    const _monData = [...hostMonitorData.value]
    // console.log(_monData)
    _monData.forEach(item => {
      item.startValue = item.value
      const r = random(100)
      item.value = item.value + r
    })
    hostMonitorData.value = _monData

    todayHost.value += 1
    growthLastDay.value += 2
    growthLastMonth.value += 5
    totalCPU.value += 1
    remainCPU.value += 1
    totalMemory.value += 1
    remainMemory.value += 1
    totalDisk.value += 1
    remainDisk.value += 1
    balanceMax.value += 4
    balanceMin.value += 3

    const _hostStatusData = { ...hostStatusData.value }
    _hostStatusData.cpuData.data1.map(item => {
      item += 1
      return item
    })
    _hostStatusData.cpuData.data2.map(item => {
      item += 1
      return item
    })
    _hostStatusData.memData.data1.map(item => {
      item += 1
      return item
    })
    _hostStatusData.memData.data1.map(item => {
      item += 1
      return item
    })
    hostStatusData.value = _hostStatusData

    const _hostSaleData = { ...hostSaleData.value }
    _hostSaleData.data1.data1 = _hostSaleData.data1.data1.map(item => {
      if (flag1) {
        item = item + 5
      } else {
        item = item - 5
      }
      return item
    })
    _hostSaleData.data1.data2 = _hostSaleData.data1.data2.map(item => {
      if (flag1) {
        item = item - 5
      } else {
        item = item + 5
      }
      return item
    })
    _hostSaleData.data2.data1 = _hostSaleData.data2.data1.map(item => {
      if (flag1) {
        item = item + 5
      } else {
        item = item - 5
      }
      return item
    })
    _hostSaleData.data2.data2 = _hostSaleData.data2.data2.map(item => {
      if (flag1) {
        item = item - 5
      } else {
        item = item + 5
      }
      return item
    })
    hostSaleData.value = _hostSaleData
    if (times > 2) {
      times = 0
      flag1 = !flag1
    } else {
      times += 1
    }

    const _headerData = { ...headerData.value }
    _headerData.headerData.forEach(item => {
      item.startVal = item.endVal
      item.endVal = item.endVal + random(1000)
    })
    _headerData.project.forEach(item => {
      item.value = `${random(100)}`
    })
    headerData.value = _headerData

    cpuRate.value += 1
  }

  onMounted(() => {
    timer = setInterval(() => {
      update()
    }, 10000)
  })

  onUnmounted(() => {
    timer && clearInterval(timer)
  })

  return {
    todayCloudHost,
    growthCloudLastDay,
    growthCloudLastMonth,
    totalCloudCPU,
    remainCloudCPU,
    totalCloudMemory,
    remainCloudMemory,
    totalCloudDisk,
    remainCloudDisk,
    hostData,
    hostMonitorData,
    todayHost,
    growthLastDay,
    growthLastMonth,
    totalCPU,
    remainCPU,
    totalMemory,
    remainMemory,
    totalDisk,
    remainDisk,
    balanceMax,
    balanceMin,
    hostStatusData,
    hostSaleData,
    headerData,
    listSum,
    listTenant,
    tenantCostData,
    tenantNum,
    tenantGroups,
    growthTenantLastDay,
    growthTenantLastMonth,
    workListData,
    radarData,
    securityListData,
    videoUrl,
    poster,
    cpuRate,
    cpuAxisData,
    cpuUsage,
    memUsage,
    diskUsage,
    resourceTop10,
    resourceListData
  }
}
