import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import TestMap from '../views/TestMap.vue'
import TestEarth from '@/views/TestEartch'
import TestMap3 from '@/views/TestMap3d'
import TestMap3d2 from '@/views/TestMap3d2'
import TestMap3d2e from '@/views/TestMap3d2e'
import TestKmMap from '@/views/TestMapKunming'
import TestYnMap from '@/views/TestMapYunNan'
import TestKmMap3d from '@/views/TestMapKunming3D'
import TestTotalUser from '@/views/TestTotalUser'
import TestAverageAge from '@/views/TestAverageAge'
import TestProject from '@/views/TestProject'
import TestGender from '@/views/TestTotalGender'
import TestTotalDebt from '@/views/TestDebt'
import TestHotCategory from '@/views/TestHotCategory'
import TestCenterHeader from '@/views/TestCenterHeader'
import TestTransformCategory from '@/views/TestTransformCategory'
import TestGdp from '@/views/TestGdp'
import TestFightEarth from '@/views/TestFightEarth'
import TestOrderMap from '@/views/TestOrderMap'
import TestMapTest from '@/views/TestMapTest'
import TestRealTimeOrder from '@/views/TestRealTimeOrder'
import TestScheduleView from '@/views/TestScheduleView'
import TestSaleList from '@/views/TestSaleList'
import TestSaleRank from '@/views/TestSaleRank'
import TestLiquidFill from '@/views/liquidFill'
import TestWordCloud from '@/views/WordCloud'
import TestTotalCloudHost from '@/views/TestTotalCloudHost'
import TestHostCategory from '@/views/TestHostCategory'
import TestHostMonitor from '@/views/TestHostMonitor'
import TestTotalHost from '@/views/TestTotalHost'
import TestTotalHostStatus from '@/views/TestHostStatus'
import TestHostSale from '@/views/TestHostSale'
import TestTenantMap from '@/views/TestTenantMap'
import TestTotalTenant from '@/views/TestTotalTenant'
import TestTotalDial from '@/views/TestTotalDial'
import TestWorkList from '@/views/TestWorkList'
import TestWarningRadar from '@/views/TestWarningRadar'
import TestSecurityList from '@/views/TestSecurityList'
import TestVideo from '@/views/TestVideo'
import TestResourceTopN from '@/views/TestResourceTopN'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('../views/TestMap.vue')
  },
  {
    path: '/earth',
    name: 'earth',
    component: TestEarth
  },
  {
    path: '/map3',
    name: 'map3',
    component: TestMap3
  },
  {
    path: '/kmmap',
    name: 'kmmap',
    component: TestKmMap
  },
  {
    path: '/ynmap',
    name: 'ynmap',
    component: TestYnMap
  },
  {
    path: '/map32',
    name: 'map32',
    component: TestMap3d2
  },
  {
    path: '/map32e',
    name: 'map32e',
    component: TestMap3d2e
  },
  {
    path: '/kmmap3d',
    name: 'kmmap3d',
    component: TestKmMap3d
  },
  {
    path: '/totalUser',
    name: 'totalUser',
    component: TestTotalUser
  },
  {
    path: '/averageAge',
    name: 'AverageAge',
    component: TestAverageAge
  },
  {
    path: '/project',
    name: 'Project',
    component: TestProject
  },
  {
    path: '/gender',
    name: 'Gender',
    component: TestGender
  },
  {
    path: '/debt',
    name: 'Debt',
    component: TestTotalDebt
  },
  {
    path: '/hot',
    name: 'Hot',
    component: TestHotCategory
  },
  {
    path: '/ch',
    name: 'CenterHeader',
    component: TestCenterHeader
  },
  {
    path: '/trf',
    name: 'Transform',
    component: TestTransformCategory
  },
  {
    path: '/gdp',
    name: 'Gdp',
    component: TestGdp
  },
  {
    path: '/fight',
    name: 'Fight',
    component: TestFightEarth
  },
  {
    path: '/order',
    name: 'OrderMap',
    component: TestOrderMap
  },
  {
    path: '/testMap',
    name: 'TestMap',
    component: TestMapTest
  },
  {
    path: '/rt',
    name: 'RealTime',
    component: TestRealTimeOrder
  },
  {
    path: '/sv',
    name: 'ScheduleView',
    component: TestScheduleView
  },
  {
    path: '/list',
    name: 'SalesList',
    component: TestSaleList
  },
  {
    path: '/rank',
    name: 'SaleRank',
    component: TestSaleRank
  },
  {
    path: '/lf',
    name: 'LiquidFill',
    component: TestLiquidFill
  },
  {
    path: '/wc',
    name: 'WordCloud',
    component: TestWordCloud
  },
  {
    path: '/hostCategory',
    name: 'hostCategory',
    component: TestHostCategory
  },
  {
    path: '/totalCloudHost',
    name: 'totalCloudHost',
    component: TestTotalCloudHost
  },
  {
    path: '/hostMonitor',
    name: 'hostMonitor',
    component: TestHostMonitor
  },
  {
    path: '/totalHost',
    name: 'totalHost',
    component: TestTotalHost
  },
  {
    path: '/totalHostStatus',
    name: 'totalHostStatus',
    component: TestTotalHostStatus
  },
  {
    path: '/hostSale',
    name: 'hostSale',
    component: TestHostSale
  },
  {
    path: '/tenantMap',
    name: 'tenantMap',
    component: TestTenantMap
  },
  {
    path: '/tenant',
    name: 'tenant',
    component: TestTotalTenant
  },
  {
    path: '/work',
    name: 'workList',
    component: TestWorkList
  },
  {
    path: '/radar',
    name: 'warningRadar',
    component: TestWarningRadar
  },
  {
    path: '/security',
    name: 'security',
    component: TestSecurityList
  },
  {
    path: '/video',
    name: 'video',
    component: TestVideo
  },
  {
    path: '/dial',
    name: 'dial',
    component: TestTotalDial
  },
  {
    path: '/top',
    name: 'top',
    component: TestResourceTopN
  }
]

const router = createRouter({
  history: createWebHistory(),
  // base: process.env.BASE_URL,
  routes
})

export default router
