<template>
  <div class="total-dial">
    <div class="text-wrapper">
      <div class="title-wrapper">
        <div class="title">{{titleName}}</div>
        <div class="sub-title">Nantian Cloud Usage of {{titleNameEn}}</div>
      </div>
    </div>
    <div class="dial-wrapper">
      <div class="dial-draft">
        <nt-echarts :options="options1"></nt-echarts>
      </div>
      <div class="dial-detail">
        <nt-echarts :options="options2"></nt-echarts>
      </div>
    </div>
  </div>
</template>

<script>
  import { onMounted, ref, watch } from 'vue'
  import NtEcharts from '../NtEcharts/NtEcharts'
  // import echarts from 'echarts'
  const color = ['#5ec9db', '#fdc765', '#f27d5e', '#6462cc', 'rgb(124,136,146)']

  export default {
    name: 'totalDial',
    props: {
      rate: {
        type: Number,
        default: 0
      },
      name: {
        type: String
      },
      name2: {
        type: String
      },
      legendData: {
        type: String
      },
      legendDataEn: {
        type: String
      },
      axisData: {
        type: Array
      },
      data: {
        type: Array
      }
    },
    components: { NtEcharts },
    setup(props) {
      const titleName = ref(props.legendData)
      const titleNameEn = ref(props.legendDataEn)
      const options1 = ref(null)
      const options2 = ref(null)

      watch(() => props.rate, (nextValue, prevValue) => {
        update()
      })
      const updateChart = () => {
        options1.value = {
          title: {
            show: true,
            text: props.name,
            textStyle: {
              color: '#ddd'
            },
            left: '38%',
            top: '-5'
          },
          series: [
            {
              type: 'gauge',
              min: 0,
              max: 100,
              splitNumber: 10,
              radius: '75%',
              axisLine: {
                // 坐标轴线
                lineStyle: {
                  // 属性lineStyle控制线条样式
                  color: [
                    [0.2, color[0]],
                    [0.5, color[1]],
                    [0.8, color[2]],
                    [1, color[3]]
                  ],
                  width: 8
                }
              },
              splitLine: {
                length: 8,
                lineStyle: {
                  color: '#fff'
                }
              },
              axisTick: {
                show: true,
                lineStyle: {
                  color: '#ddd'
                }
              },
              pointer: {
                itemStyle: {
                  color: 'rgb(42,125,204)'
                }
              },
              anchor: {
                show: true,
                showAbove: true
              },
              detail: {
                formatter: '{value}%'
              },
              data: [
                {
                  value: props.rate,
                  title: {
                    color: '#464646' // 标题颜色
                  },
                  detail: {
                    color: '#464646', // 数据详情颜色
                    offsetCenter: ['0%', '75%']
                  }
                }
              ]
            }
          ]
        }

        options2.value = {
          title: {
            show: true,
            text: props.name2,
            textStyle: {
              color: '#ddd'
            },
            left: '38%'
          },
          tooltip: {
            trigger: 'none',
            axisPointer: {
              type: 'cross'
            }
          },
          color: color,
          legend: {
            top: 20,
            right: 40,
            icon: 'rect',
            textStyle: {
              fontSize: 16,
              color: color[4]
            },
            data: props.legendData
          },
          grid: {
            top: 30,
            bottom: 30,
            left: 20,
            right: 40
          },
          xAxis: [{
            type: 'category',
            axisTick: { show: false },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: color[4]
              }
            },
            axisLabel: {
              fontSize: 16
            },
            data: props.axisData,
            boundaryGap: false
          }, {
            type: 'category',
            axisTick: { show: false },
            axisLine: { show: false },
            boundaryGap: false
          }],
          yAxis: [{
            type: 'value',
            axisTick: { show: false },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: color[4]
              }
            },
            axisLabel: {
              fontSize: 16
            },
            splitLine: {
              lineStyle: {
                type: 'dotted'
              }
            }
          }],
          series: [{
            name: props.legendData,
            type: 'line',
            xAxisIndex: 1,
            smooth: true,
            lineStyle: {
              width: 2
            },
            symbol: 'none',
            data: props.data
          }]
        }
      }
      const update = (newData) => {
        updateChart()
      }

      onMounted(() => {
        update(props.data)
      })
      return {
        titleName,
        titleNameEn,
        options1,
        options2
      }
    }
  }
</script>

<style lang="scss" scoped>
  .total-dial {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 40px 40px;
    box-sizing: border-box;

    .text-wrapper {
      display: flex;
      flex-direction: column;
      .title-wrapper {
        .title {
          font-size: 32px;
        }
        .sub-title {
          font-size: 16px;
          letter-spacing: 1px;
          margin-top: 10px;
        }
      }
    }
    .dial-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 20px;
      .dial-draft {
        height: 200px;
      }
      .dial-detail {
        height: 200px;
      }
    }
  }
</style>
