<template>
  <div class="host-status">
    <div class="title-wrapper">
      <div class="host-status-left">
        <div class="title">云主机运行状态统计</div>
        <div class="sub-title">Statistics of Host's Status</div>
      </div>
<!--      <div class="average-age-right">-->
<!--        <div class="age">-->
<!--          <count-to-->
<!--            :start-val="startAge"-->
<!--            :end-val="avgAge"-->
<!--            :duration="1000"-->
<!--            :decimals="2"-->
<!--          />-->
<!--          <span class="age-unit">岁</span>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div id="host-status-chart" >
      <nt-echarts :options="options"></nt-echarts>
    </div>
    <div class="host-status-data-wrapper">
      <div class="host-status-data" v-for="(item, index) in data" :key="index">
        <div class="host-status-data-value">
          <count-to
            :start-val="item.startValue"
            :end-val="item.value"
            :duration="1000"
          />
        </div>
        <div class="host-status-data-axis">
          <div class="point" :style="{background: item.color}" />
          <div class="text">{{item.axis}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
import countTo from '../VueCountTo/vue-countTo'
import NtEcharts from '../NtEcharts/NtEcharts'

export default {
  name: 'hostStatus',
  props: {
    data: Array,
    avgAge: Number
  },
  components: { NtEcharts, countTo },
  setup (ctx) {
    const startAge = ref(0)
    const options = ref(null)
    const updateChart = () => {
      if (!ctx || !ctx.data) {
        return
      }
      // console.log(ctx.data)
      const data = ['指标']
      const color = []
      const axis = ['指标']
      let max = 0
      // console.log(ctx.data)
      ctx.data.forEach(item => {
        data.push(+item.value)
        max += +item.value
        color.push(item.color)
        axis.push(item.axis)
      })
      // console.log(axis)
      options.value = {
        tooltip: {
          textStyle: {
            fontSize: 28
          },
          padding: 10
        },
        color,
        grid: {
          left: 40,
          right: 40,
          top: 0
        },
        dataset: {
          source: [
            axis,
            data
          ]
        },
        xAxis: {
          type: 'value',
          max,
          splitLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            color: 'rgb(98, 105, 113)',
            fontSize: 18
          },
          axisLine: {
            lineStyle: {
              color: 'rgb(50,51,53)',
              width: 3
            }
          }
        },
        yAxis: {
          type: 'category',
          show: false
        },
        series: [
          {
            type: 'bar',
            stack: 'total',
            barWidth: 15
          },
          {
            type: 'bar',
            stack: 'total'
          },
          {
            type: 'bar',
            stack: 'total'
          },
          {
            type: 'bar',
            stack: 'total'
          }
        ]
      }
      // console.log(data,color,axis,max)
    }

    watch(() => ctx.data, () => {
      // console.log('watch data')
      updateChart()
    })

    // watch(() => ctx.avgAge, (nextValue, prevValue) => {
    //   // console.log('watch number')
    //   startAge.value = prevValue
    // })

    onMounted(() => {
      updateChart()
    })

    return {
      startAge,
      options
    }
  }
}
</script>

<style lang="scss" scoped>
  .host-status {
    width: 100%;
    height: 100%;
    /*background: rgb(43, 44, 46);*/
    padding: 20px 40px;
    box-sizing: border-box;

    .title-wrapper {
      display: flex;
      align-items: center;

      .host-status-left {
        .title {
          font-size: 32px;
        }

        .sub-title {
          font-size: 16px;
          letter-spacing: 1px;
          margin-top: 10px;
        }
      }

      .host-status-right {
        flex: 1;
        margin-left: 40px;
        font-weight: bold;

        .age {
          font-size: 68px;
          font-family: DIN;

          .age-unit {
            font-size: 20px;
          }
        }
      }
    }

    #host-status-chart {
      height: 100px;
      /*background: red;*/
    }

    .host-status-data-wrapper {
       display: flex;

      .host-status-data {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        font-weight: bolder;

        .host-status-data-value {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }

        .host-status-data-axis {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          /*margin-top: 5px;*/

          .point {
            width: 10px;
            height: 10px;
            border-radius: 50%;
          }

          .text {
            margin-left: 10px;
            font-size: 16px;
          }
        }
      }
    }
  }
</style>
