<template>
  <div class="total-resource">
    <div class="text-wrapper">
      <div class="title-wrapper">
        <div class="title">{{titleName}}</div>
        <div class="sub-title">Nantian Cloud Resource of {{titleNameEn}}</div>
      </div>
    </div>
    <div class="resource-wrapper">
      <div class="resource-draft">
        <nt-echarts :options="options1"></nt-echarts>
      </div>
    </div>
  </div>
</template>

<script>
  import { onMounted, ref, watch } from 'vue'
  import NtEcharts from '../NtEcharts/NtEcharts'
  // import echarts from 'echarts'
  // const color = ['#5ec9db', '#fdc765', '#f27d5e', '#6462cc', 'rgb(124,136,146)']

  export default {
    name: 'totalResource',
    props: {
      legendData: {
        type: String
      },
      legendDataEn: {
        type: String
      },
      data: {
        type: Array
      }
    },
    components: { NtEcharts },
    setup(props) {
      const titleName = ref(props.legendData)
      const titleNameEn = ref(props.legendDataEn)
      const options1 = ref(null)

      watch(() => props.data, (nextValue, prevValue) => {
        updateChart()
      })
      const updateChart = () => {
        const total = 100 // 数据总数
        // const a = ['20%', '40%', '60%', '80%', '100%']
        const datas = []
        props.data.forEach((value) => {
          datas.push(value.value)
        })
        const ydata = props.data.map((v) => v.name)
        options1.value = {
          // backgroundColor: '#05276B',
          grid: {
            left: '8%',
            top: '0%', // 设置条形图的边距
            right: '15%',
            bottom: '3%'
          },
          xAxis: {
            splitLine: {
              show: false,
              lineStyle: {
                color: 'rgba(255,255,255,0.2)',
                type: 'dashed'
              }
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              show: false,
              color: '#ABBFE3'
            },
            axisTick: {
              show: false
            }
          },
          yAxis: [
            {
              type: 'category',
              inverse: true,
              data: ydata,
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                show: true,
                borderWidth: 20,
                textStyle: {
                  verticalAlign: 'bottom',
                  color: '#fff',
                  fontSize: 24,
                  // fontFamily: 'Microsoft YaHei',
                  align: 'left',
                  padding: [0, 0, 9, 10]
                },
                formatter: (name, index) => {
                  const _index = index + 1
                  if (index === 0) {
                    return `${_index} ${name}`
                  }
                  return `${_index} ${name}`
                }
              },
              offset: 0
            }
          ],
          series: [
            {
              // 内
              type: 'bar',
              barWidth: 14,
              barCateGoryGap: 20,
              legendHoverLink: false,
              silent: true,
              itemStyle: {
                normal: {
                  barBorderRadius: 10,
                  color: function (params) {
                    var color
                    console.log(params)
                    if (params.dataIndex <= 2) {
                      color = {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 1,
                        y2: 0,
                        colorStops: [
                          {
                            offset: 0,
                            color: '#6462cc' // 0% 处的颜色
                          },
                          {
                            offset: 1,
                            color: '#6462cc'// 100% 处的颜色
                          }
                        ]
                      }
                    } else if (params.dataIndex <= 5) {
                      color = {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 1,
                        y2: 0,
                        colorStops: [
                          {
                            offset: 0,
                            color: '#f27d51' // 0% 处的颜色
                          },
                          {
                            offset: 1,
                            color: '#f27d51' // 100% 处的颜色
                          }
                        ]
                      }
                    } else if (params.dataIndex <= 8) {
                      color = {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 1,
                        y2: 0,
                        colorStops: [
                          {
                            offset: 0,
                            color: '#fdc765'// 0% 处的颜色
                          },
                          {
                            offset: 1,
                            color: '#fdc765'// 100% 处的颜色
                          }
                        ]
                      }
                    } else {
                      color = {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 1,
                        y2: 0,
                        colorStops: [
                          {
                            offset: 0,
                            color: '#5ec9db' // 0% 处的颜色
                          },
                          {
                            offset: 1,
                            color: '#5ec9db' // 100% 处的颜色
                          }
                        ]
                      }
                    }
                    return color
                  }
                }
              },
              label: {
                normal: {
                  show: false,
                  position: '[0, 0, 15, 10]',
                  formatter: '{b}',
                  textStyle: {
                    color: '#fff',
                    fontSize: 24
                  }
                }
              },
              data: props.data,
              z: 2,
              animationEasing: 'elasticOut'
            },
            {
              // 外边框
              type: 'pictorialBar',
              symbol: 'rect',
              symbolBoundingData: total,
              itemStyle: {
                barBorderRadius: 10,
                normal: {
                  color: 'none'
                }
              },
              label: {
                // normal: {
                  padding: [0, 10, 0, 14],
                  formatter: (params) => {
                    // var text = '{d| ' + params.data + '}{f|   ' + (params.data / total) * 100 + '%}'
                    // var text = '{d| ' + '}{f|   ' + (params.data / total) * 100 + '%}'
                    var text = '{d| ' + '}{f|   ' + (params.data / total) * 100 + '%}'
                    return text
                  },
                  rich: {
                    a: {
                      color: '#D5E9FF'
                    },
                    b: {
                      color: '#D5E9FF'
                    },
                    c: {
                      color: '#D5E9FF'
                    },
                    d: {
                      color: '#D5E9FF'
                    },
                    f: {
                      color: '#fff',
                      fontWeight: 'bolder',
                      fontSize: 24
                    }
                  },
                  position: 'right',
                  distance: 1, // 向右偏移位置
                  show: true
                // }
              },
              data: datas,
              z: 0,
              animationEasing: 'elasticOut'
            },
            {
              name: '外框',
              type: 'bar',
              barCateGoryGap: 20,
              barGap: '-100%', // 设置外框粗细
              data: [
                total,
                total,
                total,
                total,
                total,
                total,
                total,
                total,
                total,
                total
              ],
              barWidth: 14,
              itemStyle: {
                normal: {
                  color: '#1A3E82', // 填充色
                  barBorderColor: '#1A3E82', // 边框色
                  barBorderWidth: 0.1, // 边框宽度
                  barBorderRadius: 10, // 圆角半径
                  label: {
                    // 标签显示位置
                    show: false,
                    position: 'top'// insideTop 或者横向的 insideLeft
                  }
                }
              },
              z: 0
            }
          ]
        }
      }
      onMounted(() => {
        updateChart()
      })
      return {
        titleName,
        titleNameEn,
        options1
      }
    }
  }
</script>

<style lang="scss" scoped>
  .total-resource {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 40px 40px;
    box-sizing: border-box;

    .text-wrapper {
      display: flex;
      flex-direction: column;
      .title-wrapper {
        .title {
          font-size: 32px;
        }
        .sub-title {
          font-size: 16px;
          letter-spacing: 1px;
          margin-top: 10px;
        }
      }
    }
    .resource-wrapper {
      margin-top: 20px;
      .resource-draft {
        height: 900px;
      }
    }
  }
</style>
