<template>
    <div id="nt-container"  :ref="refName">
        <template v-if="ready">
            <slot></slot>
        </template>
    </div>
</template>
<script>
// nextTick 渲染后再执行
import { ref, getCurrentInstance, onMounted, onUnmounted, nextTick } from 'vue'
import { debounce } from '@/utils/debounce.js'

export default {
    name: 'NtContainer',
    props: {
      options: Object
    },
    setup(ctx) {
      const refName = 'ntContainer'
      const width = ref(0)
      const height = ref(0)
      const originalWidth = ref(0)
      const originalHeight = ref(0)
      const ready = ref(false)
      let context, dom, observer

      const initSize = () => {
        // 同步方法
        return new Promise((resolve) => {
          nextTick(() => {
                // console.log('refName',refName,context)
                // console.log(context._.refs)
                // 开发环境
                // dom = context.$refs[refName]

                // nginx环境
                // dom = context._.refs

                // ...赋值
                const { refs } = context
                // console.log('refs', refs)
                dom = refs[refName]

                // console.log('dom',dom)
                // console.log(ctx)
                // 获取大屏尺寸
                if (ctx.options && ctx.options.width && ctx.options.height) {
                    width.value = ctx.options.width
                    height.value = ctx.options.height
                } else {
                    width.value = dom.clientWidth
                    height.value = dom.clientHeight
                }
                // 获取画布尺寸
                if (!originalWidth.value || !originalHeight.value) {
                    originalWidth.value = window.screen.width
                    originalHeight.value = window.screen.height
                }
                // console.log(width.value,height.value,originalWidth.value,originalHeight.value)

                resolve()
            })
        })
      }

      const updateSize = () => {
        if (width.value && height.value) {
          dom.style.width = `${width.value}px`
          dom.style.height = `${height.value}px`
        } else {
          dom.style.width = `${originalWidth.value}px`
          dom.style.height = `${originalHeight.value}px`
        }
      }

      const updateScale = () => {
        // 获取真实的视口尺寸
        const currentWidth = document.body.clientWidth
        const currentHeight = document.body.clientHeight
        // console.log(currentWidth,currentHeight)
         // 获取大屏最终的宽高
        const realWidth = width.value || originalWidth.value
        const realHeight = height.value || originalHeight.value

        const widthScale = currentWidth / realWidth
        const heightScale = currentHeight / realHeight

        // console.log(realWidth,realHeight)

        dom && (dom.style.transform = `scale(${widthScale}, ${heightScale})`)
      }

      const onResize = async (e) => {
        // console.log('onResize',e)
    //   const onResize = () =>{
            // console.log('onResize()')
            await initSize()
            // initSize()
            updateScale()
      }
      const initMutationObserver = () => {
        const MutationObserver = window.MutationObserver
        observer = new MutationObserver(onResize)
        observer.observe(dom, {
          attributes: true,
          attributeFilter: ['style'],
          attributeOldValue: true
        })
      }

      const removeMutationObserver = () => {
        if (observer) {
          observer.disconnect()
          observer.takeRecords()
          observer = null
        }
      }

    onMounted(async () => {
        ready.value = false
    // onMounted(() => {

        // const { refs } = getCurrentInstance();
        // console.log('refs', refs)

        // context = getCurrentInstance().ctx
        context = getCurrentInstance()

        await initSize()
        // initSize()
        updateSize()
        updateScale()

        window.addEventListener('resize', debounce(100, onResize))
        initMutationObserver()
        ready.value = true
      })

      // 查看vue
      // console.log(require('vue'))

      onUnmounted(() => {
        window.removeEventListener('resize', onResize)
        removeMutationObserver()
      })

      return {
        refName,
        ready
      }
    }
}
</script>
<style lang="scss" scoped>
  #nt-container {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    transform-origin: left top;
    z-index: 999;
  }
</style>
