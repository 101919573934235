<template>
  <div class="top-header">
    <div class="logo-wrapper">
      <img class="img" src="../../assets/NantianCloud.png">
<!--      <nt-logo stroke="#1890ff" stroke-width="66"></nt-logo>-->
    </div>
    <div class="logo-text">
      <div class="cn-text">{{ zhName }}</div>
      <div class="en-text">{{ enName }}</div>
    </div>
    <div class="logo-time"> TIME：{{ date }} {{ time }}</div>
  </div>
</template>

<script>
import { clock as useClock } from '../../hooks/useClock'
// import NtLogo from '../NtLogo/index'
export default {
  name: 'TopHeader',
  props: {
    zhName: {
      type: String,
      default: '南天云监控数据大盘'
    },
    enName: {
      type: String,
      default: 'Nantian Cloud Monitor Administration of Data'
    }
  },
  // components: { NtLogo },
    setup () {
    const { time, date } = useClock()
    return {
      time,
      date
    }
  }
}
</script>

<style lang="scss" scoped>
  .top-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    /*background: rgb(36, 31, 32);*/
    /*padding: 0 1364px;*/
    padding: 0 64px;
    box-sizing: border-box;
    /*text-align: center;*/

    .logo {
      width: 120px;
      height: 120px;
    }

    .logo-wrapper {
      width: 240px;
      height: 80px;
      /*padding: 0 64px;*/
      .img {
        width: 100%;
        height: 100%;
      }
    }

    .logo-text {
      flex: 1;
      margin-left: 40px;

      .cn-text {
        color: rgb(255, 255, 255);
        font-weight: bold;
        font-size: 60px;
        letter-spacing: 2px;
      }

      .en-text {
        color: rgb(255, 255, 255);
        font-size: 35px;
        letter-spacing: 1px;
      }
    }
    .logo-time{
      /*color: red;*/
      color: rgb(255, 255, 255);
      font-size: 40px;
      font-weight: lighter;
    }
  }
</style>
