<template>
  <div class="home" >
    <total-cloud-host
      :todayCloudHost="todayCloudHost"
      :growthCloudLastDay="growthCloudLastDay"
      :growthCloudLastMonth="growthCloudLastMonth"
      :totalCloudCPU="totalCloudCPU"
      :remainCloudCPU="remainCloudCPU"
      :totalCloudMemory="totalCloudMemory"
      :remainCloudMemory="remainCloudMemory"
      :totalCloudDisk="totalCloudDisk"
      :remainCloudDisk="remainCloudDisk"
    />
  </div>
</template>

<script>
  import TotalCloudHost from '@/components/TotalCloudHost/index'
  import useScreenData from '../hooks/useScreenData'

  export default {
    name: 'TestTotalCloudHost',
    components: { TotalCloudHost },
    setup () {
      return {
        ...useScreenData()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
