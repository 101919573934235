<template>
  <div class="rotating-earth">
    <nt-echarts :options="options" />
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import 'echarts-gl'
import NtEcharts from '@/components/NtEcharts/NtEcharts.vue'
// import echarts from 'echarts'

const ROOT_PATH = './'

export default {
  name: 'RotatingEarth',
  components: { NtEcharts },
  setup () {
    const options = ref({})
    const update = () => {
      options.value = {
        globe: {
          baseTexture: `${ROOT_PATH}assets/datav-gl-texture.jpg`, // 基础纹理贴图
          // baseTexture: `${ROOT_PATH}assets/tt.png`, // 基础纹理贴图
          heightTexture: `${ROOT_PATH}assets/datav-gl-texture.jpg`, // 高度纹理贴图
          // heightTexture: `${ROOT_PATH}assets/tt.png`, // 高度纹理贴图
          displacementScale: 0.04, // 地球顶点位置，可以使地球更加立体
          environment: `${ROOT_PATH}assets/star-bg.jpg`, // 背景环境贴图
          // 配置为垂直渐变的背景
          // environment: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          //   offset: 0, color: '#00aaff' // 天空颜色
          // }, {
          //   offset: 0.7, color: '#998866' // 地面颜色
          // }, {
          //   offset: 1, color: '#998866' // 地面颜色
          // }], false),
          shading: 'realistic', // 着色效果,真实感渲染
          // viewControl: {
          //   autoRotate: true,
          //   rotateSensitivity: 0
          // },
          realisticMaterial: {
            roughness: 0.5
          },
          postEffect: {
            enable: true
          },
          light: {
            main: {
              intensity: 5,
              shadow: true
            },
            ambient: {
              intensity: 1
            }
          }
        }
      }
    }
    onMounted(update)
    return { options }
  }
}
</script>

<style lang="scss" scoped>
  .rotating-earth {
    width: 100%;
    height: 100%;
  }
</style>
