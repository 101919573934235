<template>
  <div class="home" style="width:100%;height:100%">
    <yunnan-map3d
      :mapData="mapData"
      :centerMapValue="centerMapValue"
      :centerMapDetail="centerMapDetail"
      :loadFlag="loading"/>
  </div>
</template>

<script>
  import YunnanMap3d from '@/components/YunnanMap3D/index'
  import useMap from '../hooks/useMap'
  export default {
    name: 'TestMap',
    components: { YunnanMap3d },
    setup () {
      return {
        ...useMap()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    background: white;
    /*background: rgb(29, 29, 29);*/
  }
</style>
