<template>
  <div class="home" >
    <gdp-timeline-chart />
  </div>
</template>

<script>
  import GdpTimelineChart from '@/components/GdpTimelineChart/index'
  export default {
    name: 'TestGdpTimelineChart',
    components: { GdpTimelineChart },
    setup () {
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
