<template>
  <div class="nt-map" v-if="!loading">
    <nt-echarts :options="options"/>
  </div>
  <div class="nt-map" v-else>
    <div class="loading-wrapper">{{ loadingText }}</div>
  </div>
</template>

<script>
  import { ref, onMounted, onUnmounted, watch } from 'vue'
  import echarts from 'echarts'
  import NtEcharts from '@/components/NtEcharts/NtEcharts.vue'

  export default {
    name: 'YunNanMap',
    props: {
      mapData: Object,
      // centerMap: Array,
      loadFlag: {
        type: Boolean,
        default: true
      },
      centerMapValue: Object,
      travelMapDetail: Object
    },
    components: { NtEcharts },
    setup(props) {
      const options = ref({})
      const loadingText = ref('加载中.')
      const loading = ref(true)

      const colors = [
        ['#1DE9B6', '#1DE9B6', '#FFDB5C', '#FFDB5C', '#04B9FF', '#04B9FF'],
        ['#1DE9B6', '#F46E36', '#04B9FF', '#5DBD32', '#FFC809', '#FB95D5', '#BDA29A', '#6E7074', '#546570', '#C4CCD3'],
        ['#37A2DA', '#67E0E3', '#32C5E9', '#9FE6B8', '#FFDB5C', '#FF9F7F', '#FB7293', '#E062AE', '#E690D1', '#E7BCF3', '#9D96F5', '#8378EA', '#8378EA'],
        ['#DD6B66', '#759AA0', '#E69D87', '#8DC1A9', '#EA7E53', '#EEDD78', '#73A373', '#73B9BC', '#7289AB', '#91CA8C', '#F49F42']
      ]
      const colorIndex = 1

      let loadingTimer = null
      // echarts.on('legendselectchanged', function (params) {
      //   console.log(params);
      // });
      const update = () => {
        if (props.loadFlag) {
          return
        }
        // echarts.registerMap('kunming', props.mapData) // 注册地图
        options.value = {
          title: {
            text: '数字云南地图',
            subtext: '数据由南天云数据提供',
            left: '2%',
            top: '2%',
            textStyle: {
              color: '#ddd',
              fontSize: 35,
              fontWeight: 700
            }
          },
          geo: {
            map: 'yunnanming',
            zoom: 1.1,
            roam: false,
            scaleLimit: {
              min: 0.5,
              max: 3
            },
            // center: [113.83531246, 34.0267395887], // 视角的中心点
            itemStyle: {
              normal: {
                borderColor: 'rgba(147,235,248,1)',
                borderWidth: 1,
                areaColor: {
                  // 径向渐变，前三个参数分别是圆心 x, y 和半径，取值同线性渐变
                  type: 'radial',
                  x: 0.5,
                  y: 0.5,
                  r: 0.8,
                  colorStops: [{
                    offset: 0, color: 'rgba(147,235,248,0)'
                  }, {
                    offset: 1, color: 'rgba(147,235,248,0.2)'
                  }],
                  global: false // 缺省为 false
                  // 线性渐变，前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
                  // type: 'linear',
                  // x: 0,
                  // y: 0,
                  // x2: 0,
                  // y2: 1,
                  // colorStops: [{
                  //   offset: 0, color: 'rgba(147,235,248,0)' // 0% 处的颜色
                  // }, {
                  //   offset: 1, color: 'rgba(147,235,248,0.2)' // 100% 处的颜色
                  // }],
                  // global: false // 缺省为 false
                  // image: imageDom, // 支持为 HTMLImageElement, HTMLCanvasElement，不支持路径字符串
                  // repeat: 'repeat' // 是否平铺，可以是 'repeat-x', 'repeat-y', 'no-repeat'
                },
                shadowColor: 'rgba(128,217,248,1)',
                shadowOffsetX: -2,
                shadowOffsetY: 2,
                // 图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果。
                shadowBlur: 10
              },
              emphasis: {
                areaColor: '#389BB7',
                borderWidth: 0
              }
            },
            label: {
              emphasis: {
                show: false
              }
            }
          },
          series: [{
            type: 'scatter',
            coordinateSystem: 'geo',
            data: props.centerMapValue,
            // 图标大小
            symbolSize: function (val) {
                // console.log('val', val)
                return val[2] / 10
            },
            // 涟漪相关配置
            rippleEffect: {
              brushType: 'stroke'
            },
            hoverAnimation: true,
            label: {
              normal: {
                show: true,
                position: 'right',
                formatter: function (params) {
                  return params.data.name
                }
              }
            },
            itemStyle: {
              normal: {
                color: colors[colorIndex][1],
                shadowColor: colors[colorIndex][1],
                shadowBlur: 10
              }
            },
            zlevel: 1
          },
          {
            type: 'lines',
            data: props.travelMapDetail,
            effect: {
              show: true,
              period: 4,
              symbol: 'arrow',
              symbolSize: 5,
              // symbolSize: function (val) {
              //   console.log('val', val)
              //   return 3
              // },
              trailLength: 0.02
            },
            lineStyle: {
              normal: {
                color: colors[colorIndex][0],
                width: 0.1,
                opacity: 0.5,
                curveness: 0.3
              }
            },
            zlevel: 2
          }]
        }
      }

      watch(() => props.loadFlag, () => {
        if (!props.loadFlag) {
          echarts.registerMap('yunnanming', props.mapData) // 注册地图
          update()
          loading.value = false
          loadingTimer && clearInterval(loadingTimer)
        }
      })

      onMounted(() => {
        // console.log('1', props.mapData)
        // echarts.registerMap('kunming', props.mapData) // 注册地图
        update()
        loadingTimer = setInterval(() => {
          if (loadingText.value === '加载中......') {
            loadingText.value = '加载中.'
          } else {
            loadingText.value += '.'
          }
        }, 200)
      })

      onUnmounted(() => {
        loadingTimer && clearInterval(loadingTimer)
      })

      return {
        options,
        loadingText,
        loading
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nt-map {
    width: 100%;
    height: 100%;

    .loading-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      color: #fff;
      background: rgb(48, 48, 48);
    }
  }
</style>
