<template>
  <div class="home" >
    <host-sale :data="hostSaleData" />
  </div>
</template>

<script>
  import HostSale from '@/components/HostSale/index'
  import useScreenData from '../hooks/useScreenData'
  export default {
    name: 'TestHostSale',
    components: { HostSale },
    setup () {
      return {
        ...useScreenData()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
