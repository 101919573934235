<template>
  <div class="home" >
    <hot-category :data="hotCategory" />
  </div>
</template>

<script>
  import HotCategory from '@/components/HotCategory/index'
  import { onMounted, onUnmounted, ref } from 'vue'
  /* eslint-disable */
  const hotCategoryMockData = {
    "data1":{
      "axisX":["粉面粥店","简餐便当","汉堡披萨","香锅冒菜","小吃炸串","地方菜系","轻食简餐"],
      "data1":[50,29,46,52,79,69,67],
      "data2":[50,71,54,48,21,31,33]},
    "data2":{"axisX":["草莓","甘蔗","榴莲","菠萝","香蕉","梨","苹果"],
      "data1":[35,40,33,26,63,31,19],
      "data2":[65,60,67,74,37,69,81]}
  }
  /* eslint-enable */

  export default {
    name: 'TestHotCategory',
    components: { HotCategory },
    setup () {
      const hotCategory = ref(hotCategoryMockData)
      let timer
      let flag1 = true
      let times = 0
      const update = () => {
        const _hotCategoryData = { ...hotCategory.value }
        _hotCategoryData.data1.data1 = _hotCategoryData.data1.data1.map(item => {
          if (flag1) {
            item = item + 5
          } else {
            item = item - 5
          }
          return item
        })
        _hotCategoryData.data1.data2 = _hotCategoryData.data1.data2.map(item => {
          if (flag1) {
            item = item - 5
          } else {
            item = item + 5
          }
          return item
        })
        _hotCategoryData.data2.data1 = _hotCategoryData.data2.data1.map(item => {
          if (flag1) {
            item = item + 5
          } else {
            item = item - 5
          }
          return item
        })
        _hotCategoryData.data2.data2 = _hotCategoryData.data2.data2.map(item => {
          if (flag1) {
            item = item - 5
          } else {
            item = item + 5
          }
          return item
        })
        hotCategory.value = _hotCategoryData
        if (times > 2) {
          times = 0
          flag1 = !flag1
        } else {
          times += 1
        }
      }
      onMounted(() => {
        timer = setInterval(() => {
          update()
        }, 3000)
      })

      onUnmounted(() => {
        timer && clearInterval(timer)
      })
      return {
        hotCategory
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    width:100%;
    height:100%;
    /*background: white;*/
    background: rgb(29, 29, 29);
  }
</style>
