import { ref, onMounted } from 'vue'
/** 六个维度的数据 */
/* eslint-disable */
/** 昆明销售情况 */
const d1 = {
  '江苏省': 0,
  '黑龙江省': 0,
  '内蒙古': 0,
  '吉林省': 0,
  '北京市': 0,
  '辽宁省': 0,
  '河北省': 0,
  '天津市': 0,
  '山西省': 0,
  '陕西省': 0,
  '甘肃省': 0,
  '宁夏回族自治区': 0,
  '青海省': 10,
  '新疆维吾尔自治区': 0,
  '四川省': 15671,
  '重庆市': 77,
  '山东省': 0,
  '河南省': 0,
  '安徽省': 0,
  '湖北省': 3714,
  '浙江省': 0,
  '福建省': 0,
  '江西省': 0,
  '湖南省': 778,
  '贵州省': 4093,
  '云南省': 10041,
  '广东省': 0,
  '广西壮族自治区': 0,
  '海南省': 7,
  '上海市': 0

}
const d2 = {
  '江苏省': 3000,
  '黑龙江省': 8000,
  '内蒙古': 0,
  '吉林省': 10000,
  '北京市': 0,
  '辽宁省': 0,
  '河北省': 0,
  '天津市': 0,
  '山西省': 0,
  '陕西省': 0,
  '甘肃省': 0,
  '宁夏回族自治区': 0,
  '青海省': 0,
  '新疆维吾尔自治区': 0,
  '四川省': 3200,
  '重庆市': 0,
  '山东省': 0,
  '河南省': 0,
  '安徽省': 7700,
  '湖北省': 0,
  '浙江省': 0,
  '福建省': 0,
  '江西省': 0,
  '湖南省': 0,
  '贵州省': 0,
  '云南省': 0,
  '广东省': 0,
  '广西壮族自治区': 0,
  '海南省': 0,
  '上海市': 0

}
const d3 = {
  '江苏省': 11788,
  '黑龙江省': 1944,
  '内蒙古': 2954,
  '吉林省': 3482,
  '北京市': 1808,
  '辽宁省': 5488,
  '河北省': 27035,
  '天津市': 2270,
  '山西省': 13623,
  '陕西省': 4221,
  '甘肃省': 754,
  '宁夏回族自治区': 1783,
  '青海省': 91,
  '新疆维吾尔自治区': 1907,
  '四川省': 4905,
  '重庆市': 1420,
  '山东省': 39781,
  '河南省': 16154,
  '安徽省': 7914,
  '湖北省': 6802,
  '浙江省': 5812,
  '福建省': 3345,
  '江西省': 4996,
  '湖南省': 5627,
  '贵州省': 1504,
  '云南省': 2725,
  '广东省': 6339,
  '广西壮族自治区': 1009,
  '海南省': 0,
  '上海市': 1988

}
const d4 = {
  '江苏省': 0,
  '黑龙江省': 0,
  '内蒙古': 0,
  '吉林省': 0,
  '北京市': 0,
  '辽宁省': 1000,
  '河北省': 2000,
  '天津市': 3000,
  '山西省': 4000,
  '陕西省': 5000,
  '甘肃省': 6000,
  '宁夏回族自治区': 7000,
  '青海省': 0,
  '新疆维吾尔自治区': 0,
  '四川省': 0,
  '重庆市': 0,
  '山东省': 0,
  '河南省': 0,
  '安徽省': 0,
  '湖北省': 0,
  '浙江省': 0,
  '福建省': 0,
  '江西省': 0,
  '湖南省': 0,
  '贵州省': 0,
  '云南省': 0,
  '广东省': 0,
  '广西壮族自治区': 0,
  '海南省': 0,
  '上海市': 0
}
const d5 = {
  '江苏省': 159,
  '黑龙江省': 5,
  '内蒙古': 54,
  '吉林省': 10,
  '北京市': 0,
  '辽宁省': 0,
  '河北省': 1679,
  '天津市': 1,
  '山西省': 2698,
  '陕西省': 1744,
  '甘肃省': 362,
  '宁夏回族自治区': 429,
  '青海省': 122,
  '新疆维吾尔自治区': 731,
  '四川省': 3925,
  '重庆市': 1480,
  '山东省': 79,
  '河南省': 1017,
  '安徽省': 208,
  '湖北省': 1209,
  '浙江省': 1418,
  '福建省': 1237,
  '江西省': 1004,
  '湖南省': 1511,
  '贵州省': 345,
  '云南省': 1429,
  '广东省': 2242,
  '广西壮族自治区': 2271,
  '海南省': 59,
  '上海市': 8

}
const d6 = {
  '江苏省': 100,
  '黑龙江省': 200,
  '内蒙古': 300,
  '吉林省':400,
  '北京市': 500,
  '辽宁省': 600,
  '河北省': 0,
  '天津市': 0,
  '山西省': 0,
  '陕西省': 0,
  '甘肃省': 0,
  '宁夏回族自治区': 0,
  '青海省': 0,
  '新疆维吾尔自治区': 0,
  '四川省': 0,
  '重庆市': 0,
  '山东省': 0,
  '河南省': 0,
  '安徽省': 0,
  '湖北省': 0,
  '浙江省': 0,
  '福建省': 0,
  '江西省': 0,
  '湖南省': 0,
  '贵州省': 0,
  '云南省': 0,
  '广东省': 0,
  '广西壮族自治区': 0,
  '海南省': 0,
  '上海市': 0
}

const travel2017 = [{
  name: 'travel1',
  from: '云南省',
  to: '上海市',
  value: 0.1
},
  {
    name: 'travel2',
    from: '云南省',
    to: '北京市',
    value: 0.05
  },
  {
    name: 'travel3',
    from: '云南省',
    to: '广东省',
    value: 0.2
  },
  {
    name: 'travel3',
    from: '云南省',
    to: '四川省',
    value: 0.2
  },
  {
    name: 'travel3',
    from: '云南省',
    to: '西安省',
    value: 0.2
  }
  ]

const travel2018 = [{
  name: 'travel1',
  from: '云南省',
  to: '上海市',
  value: 0.1
  },
  {
    name: 'travel2',
    from: '云南省',
    to: '北京市',
    value: 0.05
  },
  {
    name: 'travel3',
    from: '云南省',
    to: '广东省',
    value: 0.2
  }]
const travel2020 = [{
  name: 'travel1',
  from: '北京市',
  to: '上海市',
  value: 0.1
},
  {
    name: 'travel2',
    from: '北京市',
    to: '云南省',
    value: 0.05
  },
  {
    name: 'travel3',
    from: '云南省',
    to: '广东省',
    value: 0.2
  }]
const travel2022 = [{
  name: 'travel1',
  from: '上海市',
  to: '重庆市',
  value: 0.1
},
  {
    name: 'travel2',
    from: '北京市',
    to: '云南省',
    value: 0.05
  },
  {
    name: 'travel3',
    from: '云南省',
    to: '广东省',
    value: 0.2
  }]
/* eslint-enable */

export default function () {
  /** 地图坐标数据 */
  const mapData = ref(null)
  /** 是否正在加载标志 */
  const loadFlag = ref(true)
  /** timeline分类 */
  const category = ref([])
  /** 柱状图统计列表（地区名） */
  const categoryData = ref([])
  /** 柱状图统计列表值（地区对应的指标值） */
  const barData = ref([])
  /** 分类对应的 地区与地区的关系 */
  const lineData = ref(null)
  /** 分类对应的 地区名、坐标、指标值 */
  const effectData = ref(null)

  function getMapValue(cityName, center) {
    let value = []
    center.forEach(item => {
      if (cityName === item.key) {
        value = item.value
      }
    })
    return value
  }

  const update = () => {
    fetch('./data/100000_full.json')
      .then(response => response.json())
      .then(data => {
        mapData.value = data
        const value1 = []
        const value2 = []
        const value3 = []
        const value4 = []
        const value5 = []
        const value6 = []
        const center = []
        mapData.value.features.forEach(item => {
          if (item.properties && item.properties.name !== '') {
            const tmp1 = (d1[item.properties.name] ? d1[item.properties.name] : 0) / 100
            const geoCoord = [...item.properties.center]

            center.push({
              key: item.properties.name,
              value: geoCoord
            })

            // console.log(geoCoord)
            value1.push({
              name: item.properties.name,
              value: tmp1,
              geoValue: geoCoord.concat(tmp1)
            })

            const tmp2 = (d2[item.properties.name] ? d2[item.properties.name] : 0) / 100
            value2.push({
              name: item.properties.name,
              value: tmp2,
              geoValue: geoCoord.concat(tmp2)
            })

            const tmp3 = (d3[item.properties.name] ? d3[item.properties.name] : 0) / 100
            value3.push({
              name: item.properties.name,
              value: tmp3,
              geoValue: geoCoord.concat(tmp3)
            })

            const tmp4 = (d4[item.properties.name] ? d4[item.properties.name] : 0) / 100
            value4.push({
              name: item.properties.name,
              value: tmp4,
              geoValue: geoCoord.concat(tmp4)
            })

            const tmp5 = (d5[item.properties.name] ? d5[item.properties.name] : 0) / 100
            value5.push({
              name: item.properties.name,
              value: tmp5,
              geoValue: geoCoord.concat(tmp5)
            })

            const tmp6 = (d6[item.properties.name] ? d6[item.properties.name] : 0) / 100
            value6.push({
              name: item.properties.name,
              value: tmp6,
              geoValue: geoCoord.concat(tmp6)
            })
          }
        })
        value1.sort(function sortNumber(a, b) {
          return a.value - b.value
        })
        value2.sort(function sortNumber(a, b) {
          return a.value - b.value
        })
        value3.sort(function sortNumber(a, b) {
          return a.value - b.value
        })
        value4.sort(function sortNumber(a, b) {
          return a.value - b.value
        })
        value5.sort(function sortNumber(a, b) {
          return a.value - b.value
        })
        value6.sort(function sortNumber(a, b) {
          return a.value - b.value
        })
        const _barData = []
        const _categoryData = []
        const _effectData = []
        _barData.push([])
        _categoryData.push([])
        _effectData.push([])
        for (let i = 0; i < value1.length; i++) {
          _barData[0].push(value1[i].value)
          _categoryData[0].push(value1[i].name)
          _effectData[0].push({
              name: value1[i].name,
              value: value1[i].geoValue
          })
        }
        _barData.push([])
        _categoryData.push([])
        _effectData.push([])
        for (let i = 0; i < value2.length; i++) {
          _barData[1].push(value2[i].value)
          _categoryData[1].push(value2[i].name)
          _effectData[1].push({
            name: value2[i].name,
            value: value2[i].geoValue
          })
        }
        _barData.push([])
        _categoryData.push([])
        _effectData.push([])
        for (let i = 0; i < value3.length; i++) {
          _barData[2].push(value3[i].value)
          _categoryData[2].push(value3[i].name)
          _effectData[2].push({
            name: value3[i].name,
            value: value3[i].geoValue
          })
        }
        _barData.push([])
        _categoryData.push([])
        _effectData.push([])
        for (let i = 0; i < value4.length; i++) {
          _barData[3].push(value4[i].value)
          _categoryData[3].push(value4[i].name)
          _effectData[3].push({
            name: value4[i].name,
            value: value4[i].geoValue
          })
        }
        _barData.push([])
        _categoryData.push([])
        _effectData.push([])
        for (let i = 0; i < value5.length; i++) {
          _barData[4].push(value5[i].value)
          _categoryData[4].push(value5[i].name)
          _effectData[4].push({
            name: value5[i].name,
            value: value5[i].geoValue
          })
        }
        _barData.push([])
        _categoryData.push([])
        _effectData.push([])
        for (let i = 0; i < value6.length; i++) {
          _barData[5].push(value6[i].value)
          _categoryData[5].push(value6[i].name)
          _effectData[5].push({
            name: value5[i].name,
            value: value5[i].geoValue
          })
        }
        barData.value = _barData
        categoryData.value = _categoryData
        effectData.value = _effectData

        const _travelMapDetail = []
        _travelMapDetail.push([])
        travel2017.forEach(item => {
          _travelMapDetail[0].push([
            {
              coord: getMapValue(item.from, center),
              value: item.value
            }, {
              coord: getMapValue(item.to, center)
            }
          ])
        })
        _travelMapDetail.push([])
        travel2018.forEach(item => {
          _travelMapDetail[1].push([
            {
              coord: getMapValue(item.from, center),
              value: item.value
            }, {
              coord: getMapValue(item.to, center)
            }
          ])
        })
        _travelMapDetail.push([])
        _travelMapDetail.push([])
        travel2020.forEach(item => {
          _travelMapDetail[3].push([
            {
              coord: getMapValue(item.from, center),
              value: item.value
            }, {
              coord: getMapValue(item.to, center)
            }
          ])
        })
        _travelMapDetail.push([])
        _travelMapDetail.push([])
        travel2022.forEach(item => {
          _travelMapDetail[5].push([
            {
              coord: getMapValue(item.from, center),
              value: item.value
            }, {
              coord: getMapValue(item.to, center)
            }
          ])
        })
        lineData.value = _travelMapDetail

        loadFlag.value = false
      })
    category.value = ['昆明', '北京', '上海', '广州', '成都', '西安']
  }

  onMounted(() => {
    update()
  })
  return {
    mapData,
    category,
    categoryData,
    barData,
    lineData,
    effectData,
    loadFlag
  }
}
