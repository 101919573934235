<template>
  <div class="home">
    <nt-loading v-if="loading" >
      <div class="loading-text">Loading。。。</div>
    </nt-loading>
    <container :options="{ width: 3840, height: 2160 }" v-else>
      <div class="header">
        <top-header/>
      </div>
      <div class="separator"/>
      <div class="center">
        <div class="left">
          <div class="left-1">
            <total-cloud-host
              :todayCloudHost="todayCloudHost"
              :growthCloudLastDay="growthCloudLastDay"
              :growthCloudLastMonth="growthCloudLastMonth"
              :totalCloudCPU="totalCloudCPU"
              :remainCloudCPU="remainCloudCPU"
              :totalCloudMemory="totalCloudMemory"
              :remainCloudMemory="remainCloudMemory"
              :totalCloudDisk="totalCloudDisk"
              :remainCloudDisk="remainCloudDisk"
            />
          </div>
          <div class="left-2">
            <total-host-category :data="hostData"  />
          </div>
          <div class="left-3">
            <host-monitor :data="hostMonitorData"  />
          </div>
          <div class="left-4">
            <total-host
              :todayHost="todayHost"
              :growthLastDay="growthLastDay"
              :growthLastMonth="growthLastMonth"
              :totalCPU="totalCPU"
              :remainCPU="remainCPU"
              :totalMemory="totalMemory"
              :remainMemory="remainMemory"
              :totalDisk="totalDisk"
              :remainDisk="remainDisk"
              :balanceMax="balanceMax"
              :balanceMin="balanceMin"
            />
          </div>
          <div class="left-5">
            <total-host-status :data="hostStatusData" />
          </div>
          <div class="left-6">
            <host-sale :data="hostSaleData" />
          </div>
        </div>
        <div class="right">
          <div class="right-top">
            <center-header :data="headerData" />
          </div>
          <div class="right-bottom">
            <div class="right-left">
              <div class="right-left1">
                <tenant-map
                  :mapData="mapData"
                  :category="category"
                  :categoryData="categoryData"
                  :barData="barData"
                  :lineData="lineData"
                  :effectData="effectData"
                  :loadFlag="loadFlag" />
<!--                <nt-video-play :videoUrl="videoUrl" :poster="poster" />-->
              </div>
              <div class="right-left2">
<!--                <nt-fly-box startColor="rgb(253,251,142)" duration="5">-->
<!--                  <total-Tenant-->
<!--                    :tenantNum="tenantNum"-->
<!--                    :tenantGroups="tenantGroups"-->
<!--                    :growthTenantLastDay="growthTenantLastDay"-->
<!--                    :growthTenantLastMonth="growthTenantLastMonth"-->
<!--                    :listSum="listSum"-->
<!--                    :listTenant="listTenant"-->
<!--                    :data="tenantCostData"-->
<!--                  />-->
                  <div class="right-left2-left1">
                    <total-dial
                      :rate="cpuRate"
                      name="CPU Average usage rate"
                      name2="CPU Peak usage rate"
                      legendData="CPU usage rate"
                      legendDataEn="CPU"
                      :axisData="cpuAxisData"
                      :data="cpuUsage"
                    />
                  </div>
                  <div class="right-left2-left2">
                    <total-dial
                      :rate="cpuRate"
                      name="Memory Average usage rate"
                      name2="Memory Peak usage rate"
                      legendData="Memory usage rate"
                      legendDataEn="Memory"
                      :axisData="cpuAxisData"
                      :data="memUsage"
                    />
                  </div>
                  <div class="right-left2-left3">
                    <total-dial
                      :rate="cpuRate"
                      name="Disk Average usage rate"
                      name2="Disk Peak usage rate"
                      legendData="Disk usage rate"
                      legendDataEn="Disk"
                      :axisData="cpuAxisData"
                      :data="diskUsage"
                    />
                  </div>
<!--                </nt-fly-box>-->
              </div>
            </div>
            <div class="right-right">
              <div class="right-right1">
<!--                <work-list :data="workListData" />-->
                <total-resource-top-n
                  legendData="南天云资源使用排名"
                  legendDataEn="Top10"
                  :data="resourceTop10"
                />
              </div>
              <div class="right-right2">
<!--                <warning-radar :data="radarData" />-->
                <resource-list :data="resourceListData" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue'
  import Container from '@/components/Container/Container.vue'
  import TopHeader from '@/components/TopHeader/index.vue'
  // import YunnanMapWarning from '@/components/YunnanMapWarning/indexMap'
  import useScreenData from '../hooks/useScreenData'
  // import RotatingEarth from '@/components/RotatingEarth/index'
  // import YunnanMap3d from '@/components/YunnanMap3D/index'
  // import KunmingMap from '@/components/KunmingMap/index'
  // import useKunmingMap from '../hooks/useKmMap'
  import TotalCloudHost from '@/components/TotalCloudHost/index'
  import TotalHostCategory from '@/components/TotalHostCategory/index'
  import HostMonitor from '@/components/HostMonitor/index'
  import TotalHost from '@/components/TotalHost/index'
  import TotalHostStatus from '@/components/TotalHostStatus/index'
  import HostSale from '@/components/HostSale/index'
  import CenterHeader from '@/components/CenterHeader/index'
  import TenantMap from '@/components/TenantMap/index'
  // import TotalTenant from '@/components/TotalTenant/index'
  // import NtFlyBox from '@/components/NtFlyBox/NtFlyBox'
  // import WorkList from '../components/WorkList/index'
  // import WarningRadar from '../components/WarningRadar/index'
  // import SecurityList from '../components/SecurityList/index'
  import NtLoading from '../components/NtLoading/index'
  // import NtVideoPlay from '@/components/VideoPlay/index'
  import TotalDial from '@/components/TotalDial/index'
  import TotalResourceTopN from '@/components/TotalResourceTopN/index'
  import ResourceList from '../components/ResourceList/index'
  import useTenantMapData from '../hooks/useTenantMapData'

  export default {
    name: 'HomeView',
    // components: { SecurityList, WarningRadar, WorkList, NtFlyBox, TotalTenant, TenantMap, CenterHeader, HostSale, TotalHostStatus, TotalHost, HostMonitor, TotalHostCategory, TotalCloudHost, TopHeader, Container },
    components: { TenantMap, ResourceList, TotalResourceTopN, TotalDial, NtLoading, CenterHeader, HostSale, TotalHostStatus, TotalHost, HostMonitor, TotalHostCategory, TotalCloudHost, TopHeader, Container },
    setup () {
      const loading = ref(true)

      onMounted(() => {
        setTimeout(() => {
          loading.value = false
        }, 2500)
      })
      return {
        loading,
        ...useScreenData(),
        ...useTenantMapData()
      }
    }
  }
</script>

<style lang="scss">
  .home {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /*background: rgb(29, 29, 29);*/
    background: url('../assets/bg.jpg');
    color: #fff;
    font-size: 48px;

    .loading-text{
      font-size: 20px;
      margin-top: 10px;
    }

    #nt-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .header {
        /*padding-top: 45px;*/
        width: 100%;
        height: 167px;
      }

      .separator {
        width: 100%;
        height: 10px;
        background: rgb(92, 88, 89);
      }

      .center {
        flex: 1;
        display: flex;
        width: 100%;

        .left {
          flex: 0 0 1000px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 1000px;
          height: 100%;
          padding-bottom: 20px;
          box-sizing: border-box;
          .left-1{
            height: 300px;
            /*background: grey;*/
            margin-bottom: 1px;
          }
          .left-2{
            height: 260px;
            /*background: grey;*/
            margin-bottom: 1px;
          }
          .left-3{
            height: 320px;
            /*background: grey;*/
            margin-bottom: 1px;
          }
          .left-4{
            height: 260px;
            /*background: grey;*/
            margin-bottom: 1px;
          }
          .left-5{
            height: 400px;
            /*background: grey;*/
            margin-bottom: 1px;
          }
          .left-6{
            flex: 1;
            /*background: grey;*/
            margin-bottom: 10px;
          }
        }
        .right {
          flex: 1;
          display: flex;
          flex-direction: column;
          margin-left: 1px;
          /*margin-right: 10px;*/
          justify-content: space-between;
          padding-bottom: 20px;
          box-sizing: border-box;
          width: 100%;

          .right-top{
            height: 206px;
            width: 100%;
            /*background: gray;*/
            margin-bottom: 1px;
            box-sizing: border-box;
          }
          .right-bottom {
            flex: 1;
            display: flex;
            /*padding-bottom: 20px;*/

            .right-left{
              flex: 0 0 1917px;
              display: flex;
              flex-direction: column;
              width: 1917px;
              justify-content: space-between;
              .right-left1{
                width: 100%;
                height: 1099px;
                /*background: grey;*/
                margin-bottom: 1px;
                box-sizing: border-box;
              }
              .right-left2{
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                /*background: grey;*/
                margin-bottom: 10px;
                box-sizing: border-box;
                justify-content: space-between;
                .right-left2-left1{
                  width: 630px;
                  /*background: grey;*/
                }
                .right-left2-left2{
                  width: 630px;
                  /*background: grey;*/
                }
                .right-left2-left3{
                  flex: 1;
                  /*background: grey;*/
                }
              }
            }
            .right-right{
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-left: 1px;
              .right-right1{
                height: 950px;
                /*background: grey;*/
                margin-bottom: 10px;
              }
              .right-right2{
                flex: 1;
                margin-top: 50px;
                /*background: grey;*/
                margin-bottom: 1px;
              }
            }
          }
        }
      }
    }
  }
</style>
